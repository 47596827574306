import React, { useState , useEffect } from 'react';
import { Link } from 'react-router-dom';
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import swal from 'sweetalert';

import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import ReceiptIcon from '@material-ui/icons/Receipt';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import {Helmet} from "react-helmet";



import pay from '../assets/images/pay.png';



import icon1 from '../assets/images/icon1 1.png';
import icon2 from '../assets/images/icon2 1.png';
import icon3 from '../assets/images/icon3 1.png';
import icon4 from '../assets/images/icon4 1.png';








const SubCategoriesProduct = (props)=> {
    const [CategoriesUrl , setCategoriesUrl] = useState("")
    const [Product , setProduct] = useState([])
    const [Product1 , setProduct1] = useState([])
    const [Product2 , setProduct2] = useState([])
    const [Categories , setCategories] = useState([])
    const [fal , setFal] = useState(false)
    const [fal1 , setFal1] = useState(false)
    const [CategoriesName , setCategoriesname] = useState({})
    const [SubCategories , setSubCategories] = useState([])
    const [SubClassCategories , setSubClassCategories] = useState([])
    const [subCate , setsubCate] = useState([])
    const [allproduct , setallproduct] = useState([])
    const [Subproduct , setSubproduct] = useState([])
    const [Subproduct1 , setSubproduct1] = useState([])
    const [SortedData , setSortedData] = useState([])
    const [SortedName , setSortedName] = useState([])
    const [SliderPhoto , setSliderPhoto] = useState([])
    const [DataPart2 , setDataPart2] = useState([])
    const [MainCatogories , setMainCatogories] = useState([])
    const [dispaluy53 , setdispaluy53] = useState(true)
    const [Cate1 , setCate1] = useState([])
    const [Cate2 , setCate2] = useState([])
    const [Cities , setCities] = useState(true)

    // categoriesName subcategories

    useEffect(() =>{

        window.scrollTo(0, 0)
        // console.log( props.match.params);
        let Categoriesname = props.match.params ;
        setCategoriesname(Categoriesname)

        // const Inter = setInterval(()=>{
            //  if (!JSON.parse(localStorage.getItem("CateProduct")) ){
            //  var data1 = JSON.parse(localStorage.getItem("Cate")) 

        // var Category =  props.match.url.split("/")
        // setCategoriesUrl(data1)
        // var Category1 =  CategoriesUrl.split("-").join(" ")
        fetch("https://pak-dream-back.vercel.app/AllProduct",{
                    method: "GET",
                    headers :  {
                    "Content-Type" : "application/json" , 
                } ,
                })
                .then(res7=>res7.json())
                .then(res8=>{
                    setallproduct(res8)
                    const NonActive = res8.filter((res9,i)=>{
                        // console.log(res9.status , res9.Product_Catagories , "Cars"  , res9.Product_Sub_Catagories , Categoriesname.subCate );
                        if(res9.status && res9.Product_Catagories === "Cars" && res9.vehicles === "Luxury" ){
                            return res9 
                        }
                    })

                    setSubproduct(NonActive)
                    console.log(res8)
                })

        
       

                // fetch("https://pak-dream-back.vercel.app/AllProduct",{
                //     method: "GET",
                //     headers :  {
                //     "Content-Type" : "application/json" , 
                // } ,
                // })
                // .then(res7=>res7.json())
                // .then(res8=>{
                //     setallproduct(res8)
                //     const NonActive = res8.filter((res9,i)=>{
                //         console.log(res9.status , res9.Product_Catagories , "Cars"  , res9.Product_Sub_Catagories , Categoriesname.subCate );
                //         if(res9.status && res9.Product_Catagories === "Cars"  ){
                //             return res9 
                //         }
                //     })

                //     setSubproduct(NonActive)
                //     console.log(NonActive)
                // })

        

      



},[])


    const setSortPrice1 = (e)=>{
      setdispaluy53(!dispaluy53)
      if(Subproduct.length !== 0){

        SortedName.push(e)
        console.log(e,Subproduct);
        if (e === "Low"){
            const Pro6 = Subproduct.sort(function (a, b) {
                    return parseFloat(a.Product_Price) - parseFloat(b.Product_Price)  
                  });
                  console.log(Pro6);
                  let reversed_array = [];
                  Pro6.forEach((element) => {
                    reversed_array.unshift(element);
                });
                  console.log(Pro6,reversed_array)
                  setSubproduct(reversed_array)
                  setSubproduct(Pro6)
                  setdispaluy53(!dispaluy53)
                  setdispaluy53(!dispaluy53)
    
        }
        if (e === "High"){
            const Pro6 = Subproduct.sort((a, b)=> {
                    return parseFloat(b.Product_Price) - parseFloat(a.Product_Price)  
                    
                });
              //   let reversed_array = [];
              //   Pro6.forEach((element) => {
              //     reversed_array.unshift(element);
              // });
              //   console.log(Pro6,reversed_array)
                setSubproduct(Pro6)
                setdispaluy53(!dispaluy53)
                setdispaluy53(!dispaluy53)

        }
        if (e === "A"){
            const Pro6 = Subproduct.sort((a, b)=> {
                var nameA = a.Product_Name.toUpperCase(); 
                var nameB = b.Product_Name.toUpperCase(); 
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
    
                return 0;
                });
                console.log(Pro6);
                setSubproduct(Pro6)
                
              }
              
              setSubproduct1([])
              setdispaluy53(!dispaluy53)

            }
            else{
        
                SortedName.push(e)
                console.log(e);
                if (e === "Low"){
                    const Pro6 = Subproduct.sort((a, b)=> {
                            return parseFloat(b.Product_Price) - parseFloat(a.Product_Price)  
                            
                        });
                        setSubproduct1(Pro6)
            
                }
                if (e === "High"){
                    const Pro6 = Subproduct.sort((a, b)=> {
                            return parseFloat(b.Product_Price) - parseFloat(a.Product_Price)  
                            
                        });
                        setSubproduct1(Pro6.reverse())
            
                }
                if (e === "A"){
                    const Pro6 = Subproduct.sort((a, b)=> {
                        var nameA = a.Product_Name.toUpperCase(); 
                        var nameB = b.Product_Name.toUpperCase(); 
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }
            
                        return 0;
                        });
                        console.log(Pro6);
                        setSubproduct1(Pro6)
                        
                      }
                      
                      
                      setSubproduct([])
                    }
                    setdispaluy53(!dispaluy53)

                  }
  

  
const addtocartproduct = (productDetail) =>{
  swal("Update!", "Your Item Add in Cart!", "success");

      
  // document.getElementById("myDi").style.visibility = "visible"
  // setTimeout(()=>{
  // document.getElementById("myDi").style.visibility = "hidden"

  // },1000)
  console.log(productDetail)
  if (productDetail[0].Product_Price_Discounted === null){
  const data =  {...productDetail[0] ,
      Percentage : 0,
      DeliveryStatus : "Pending",
      Pieces : 1 ,
      Total_Product_Price  : productDetail[0].Product_Price * 1 }
  var data1 = JSON.parse(localStorage.getItem("Cart")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id ){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
  return {...item,
      Pieces : 1 + item.Pieces,
      Total_Product_Price  : (productDetail[0].Product_Price * 1 )+ item.Total_Product_Price}
  }
  else{
  console.log("double not match");
  return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Cart")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Cart" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Cart" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Cart" , JSON.stringify([data]) )

  }

  }
  else if (productDetail[0].Product_Price_Discounted){
  const data =  {...productDetail[0] ,
      Percentage : 0,
      DeliveryStatus : "Pending",
      Pieces : 1 ,
      Total_Product_Price  : productDetail[0].Product_Price_Discounted * 1 }
  var data1 = JSON.parse(localStorage.getItem("Cart")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id ){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
  return {...item,
      Pieces : 1 + item.Pieces,
      Total_Product_Price  : (productDetail[0].Product_Price_Discounted * 1 )+ item.Total_Product_Price}
  }
  else{
  console.log("double not match");
  return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Cart")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Cart" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Cart" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Cart" , JSON.stringify([data]) )

  }

  }
  else if (productDetail[0].Size_Discounted[0]){
      
          const data =  {...productDetail[0] ,
              Percentage : 0,
              DeliveryStatus : "Pending",
              Pieces : 1 ,
              Size : productDetail[0].Size[0],
              Product_Price_Discounted : productDetail[0].Size_Discounted[0],
              Total_Product_Price  : productDetail[0].Size_Discounted[0] * 1 }
  var data1 = JSON.parse(localStorage.getItem("Cart")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
      return {...item,
          Pieces : 1 + item.Pieces,
          Total_Product_Price  : (productDetail[0].Size_Discounted[0] * 1 )+ item.Total_Product_Price}
  }
  else{
      console.log("double not match");
      return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Cart")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Cart" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Cart" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Cart" , JSON.stringify([data]) )

  }

  }
  else{




          const data =  {...productDetail[0] ,
              Percentage : 0,
              DeliveryStatus : "Pending",
              Pieces : 1 ,
              Size : productDetail[0].Size[0],
              Product_Price_Discounted : productDetail[0].Price[0],
              Total_Product_Price  : productDetail[0].Price[0] * 1 }
  var data1 = JSON.parse(localStorage.getItem("Cart")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
      return {...item,
          Pieces : 1 + item.Pieces,
          Total_Product_Price  : (productDetail[0].Price[0] * 1 )+ item.Total_Product_Price}
  }
  else{
      console.log("double not match");
      return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Cart")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Cart" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Cart" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Cart" , JSON.stringify([data]) )

  }


  }


      

}


const addtocartproduct8 = (productDetail) =>{
    swal("Update!", "Item Save!", "success");
  
        
    // document.getElementById("myDi").style.visibility = "visible"
    // setTimeout(()=>{
    // document.getElementById("myDi").style.visibility = "hidden"
  
    // },1000)
    console.log(productDetail)
    if (productDetail[0].Product_Price_Discounted === null){
    const data =  {...productDetail[0] ,
        Percentage : 0,
        DeliveryStatus : "Pending",
        Pieces : 1 ,
        Total_Product_Price  : productDetail[0].Product_Price * 1 }
    var data1 = JSON.parse(localStorage.getItem("Fav")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id ){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
    return {...item,
        Pieces : 1 + item.Pieces,
        Total_Product_Price  : (productDetail[0].Product_Price * 1 )+ item.Total_Product_Price}
    }
    else{
    console.log("double not match");
    return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Fav")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Fav" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Fav" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Fav" , JSON.stringify([data]) )
  
    }
  
    }
    else if (productDetail[0].Product_Price_Discounted){
    const data =  {...productDetail[0] ,
        Percentage : 0,
        DeliveryStatus : "Pending",
        Pieces : 1 ,
        Total_Product_Price  : productDetail[0].Product_Price_Discounted * 1 }
    var data1 = JSON.parse(localStorage.getItem("Fav")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id ){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
    return {...item,
        Pieces : 1 + item.Pieces,
        Total_Product_Price  : (productDetail[0].Product_Price_Discounted * 1 )+ item.Total_Product_Price}
    }
    else{
    console.log("double not match");
    return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Fav")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Fav" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Fav" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Fav" , JSON.stringify([data]) )
  
    }
  
    }
    else if (productDetail[0].Size_Discounted[0]){
        
            const data =  {...productDetail[0] ,
                Percentage : 0,
                DeliveryStatus : "Pending",
                Pieces : 1 ,
                Size : productDetail[0].Size[0],
                Product_Price_Discounted : productDetail[0].Size_Discounted[0],
                Total_Product_Price  : productDetail[0].Size_Discounted[0] * 1 }
    var data1 = JSON.parse(localStorage.getItem("Fav")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
        return {...item,
            Pieces : 1 + item.Pieces,
            Total_Product_Price  : (productDetail[0].Size_Discounted[0] * 1 )+ item.Total_Product_Price}
    }
    else{
        console.log("double not match");
        return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Fav")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Fav" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Fav" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Fav" , JSON.stringify([data]) )
  
    }
  
    }
    else{
  
  
  
  
            const data =  {...productDetail[0] ,
                Percentage : 0,
                DeliveryStatus : "Pending",
                Pieces : 1 ,
                Size : productDetail[0].Size[0],
                Product_Price_Discounted : productDetail[0].Price[0],
                Total_Product_Price  : productDetail[0].Price[0] * 1 }
    var data1 = JSON.parse(localStorage.getItem("Fav")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
        return {...item,
            Pieces : 1 + item.Pieces,
            Total_Product_Price  : (productDetail[0].Price[0] * 1 )+ item.Total_Product_Price}
    }
    else{
        console.log("double not match");
        return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Fav")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Fav" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Fav" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Fav" , JSON.stringify([data]) )
  
    }
  
  
    }
  
  
        
  
  }
  
        return (
       




<>
            <Helmet>
            <link rel="canonical" href="https://pakdreamrentacar.com/luxury-cars" /> 
            <meta name="description" content="Experience the epitome of elegance and sophistication with Pak Dream. Discover our exclusive collection of luxury cars available for rent and make a statement wherever you go" />

                       </Helmet>
           
          <main className="main">
          <div className="text-center">
            <div className="container">
              <h1 className="page-title" style={{fontSize:"27px",fontWeight:"bold", margin:"0px 10px",padding:0,textTransform:"uppercase"}}>Luxury Cars on Rent
              <span></span></h1>
              <p>
              Luxury cars embody sophistication, comfort, and exclusivity. Renting one of our premium vehicles offers you the chance to experience the highest levels of luxury without the long-term financial commitment. At Pak Dream Rent A Car, we make it easy for you to enjoy a world-class driving experience at an affordable price.

              </p>
            </div>{/* End .container */}
          </div>{/* End .page-header */}
<hr  style={{margin:"0px"}}/>
          <div className="page-content">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="toolbox">
                    <div className="toolbox-left" style={{width:"100%"}}>
                      <div className="toolbox-info" style={{width:"100%"}}>
                     <div className='dskjffkj' style={{width:"100%",display:"flex",color : "black"}}>
                       <div className='sfsdf' style={{display:"flex",flexDirection :"column",width:"140px",marginRight : "100px"}}>
                         <span style={{color : "black",fontSize:"17px"}}>Price </span>
                         <span style={{marginRight : "20px",width:"140px",color : "black",fontSize:"15px"}} > Rs : 4500 to 40000 </span>
                         <div style={{display:"flex"}}>

                         <input className='gklfgkl' type="range" name="" id="" value={0} min="0" max="9500" style={{color:"blue",color : "black",fontSize:"17px",padding:"0px",width:"60px"}}/>
                         <input className='gklfgkl' type="range" name="" id="" value={19500} min="9500" max="40000" style={{color:"blue",color : "black",fontSize:"17px",padding:"0px",width:"60px"}}/>
                         </div>
                        </div>
                        <div>
                          <span style={{color : "black",fontSize:"18px"}}>{Subproduct.length} <b>Cars</b>  founds</span>
                        </div>
                        </div> 
                        
                      </div>{/* End .toolbox-info */}
                    </div>{/* End .toolbox-left */}
                    <div className="toolbox-right" style={{width:"100%",justifyContent:"right",alignItems:"flex-end"}}>
                      <div className="toolbox-sort">
                        <label htmlFor="sortby">Sort by:</label>
                        <div className="select-custom">
                          <select name="sortby" id="sortby" className="form-control">
                            <option value="popularity" selected="selected">Most Popular</option>
                            <option value="Low" onClick={()=>setSortPrice1("Low")}>Low → High</option>
                          <option value="High" onClick={()=>setSortPrice1("High")}>High → Low</option>
                          <option value="A" onClick={()=>setSortPrice1("A")}>A → Z</option>
                            <option value="rating">Most Rated</option>
                            <option value="date">Date</option>
                          </select>
                        </div>
                      </div>{/* End .toolbox-sort */}
                      
                    </div>{/* End .toolbox-right */}
                  </div>{/* End .toolbox */}
                  <div style={{display:"flex",justifyContent:"space-evenly",color:"black",fontSize:"19px",fontWeight:"500"}}>

{/* <input className='gklfgkl' type="radio" name="" id="" value={0} min="0" max="9500" style={{color:"blue",color : "black",fontSize:"17px",padding:"0px",width:"60px"}}/>
<input className='gklfgkl' type="radio" name="" id="" value={19500} min="9500" max="20000" style={{color:"blue",color : "black",fontSize:"17px",padding:"0px",width:"60px"}}/> */}
<span>
 <input className='gklfgkl' type="radio" value={Cities} name="Cities1"  onClick={()=>setCities(true)}  style={{accentColor:"firebrick", backgroundColor:"#EFE5CE",border: "10px solid #EFE5CE",marginRight:"9px"}}/> In City
</span>
<span>
 <input className='gklfgkl' type="radio" value={Cities} name="Cities1"  onClick={()=>setCities(false)}   style={{accentColor:"firebrick", backgroundColor:"#EFE5CE",border: "10px solid #EFE5CE",marginRight:"9px"}}/> Out City

</span>
<span>
 <input className='gklfgkl' type="radio" value={Cities} name="Cities1"  onClick={()=>setCities(true)}   style={{accentColor:"firebrick", backgroundColor:"#EFE5CE",border: "10px solid #EFE5CE",marginRight:"9px"}}/> Short Rental

</span>

</div>
                  <div className="products mb-3">
                    <div className="row justify-content-center">
                    {Cities ? 
                    Subproduct.map((res,i)=>{
                        return(
      <div className="col-12 col-md-6 col-lg-6 col-xl-4">
                              <div className="product product-7 text-center" style={{height : "auto",
    border: "2px solid rgb(149, 6, 13)",
    borderRadius: "20px",
    padding: "10px 0px",
    margin: "11px"}}>
                                <figure className="product-media">
                                  <Link  to={"/"+res.Product_Name_Full}>
                                    <img src={res.Product_Image_Upload} alt="Product image" className="product-image" />
                                  </Link>
                                  <div className="product-action-vertical">
                                    <a className="btn-product-icon btn-wishlist btn-expandable"  onClick={()=>addtocartproduct8([res])}><span>add to wishlist</span></a>
                                    <Link to={"/"+res.Product_Name_Full} className="btn-product-icon btn-quickview" title="Quick view"><span>Quick view</span></Link>
                                  </div>{/* End .product-action-vertical */}
                                </figure>{/* End .product-media */}
                                <div className="product-body">
                                  <div className="product-cat">
                                  </div>{/* End .product-cat */}
                                  <h3 className="product-title"   style={{maxWidth:"100%",maxHeight : "59px",overflow : "hidden",textAlign:"left"}}><Link to={"/"+res.Product_Name_Full}>{res.Product_Name}</Link></h3>{/* End .product-title */}


                            <div style={{display :"flex",flexDirection : "row",justifyContent :"space-between"}}>
                            <div>
                                <div style={{display:"flex",margin:"0px 0px 15px 0px"}}>
                                <img src={icon1} alt="Pak Dream" style={{height:"23px",width:"20px",color: "#95060D",fontSize : "15",marginRight  :"10px"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.person && res.person } Seats</p>
                            </div>
                                <div style={{display:"flex",}}>
                                <img src={icon2} alt="Pak Dream" style={{height:"23px",width:"20px",color: "#95060D",fontSize : "15",marginRight  :"10px"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>Automatic</p>
                            </div>
                            </div>
                            <div>
                               
                                <div style={{display:"flex",flexDirection:"column"}}>
                                  <div>

                                {res.Product_Price_Discounted ? 
                  <div style={{display:"flex"}}>
                  <del><span className="product-price" style={{fontSize:"13px",fontWeight:"bold",color:"black"}}>Rs {res.Product_Price} <br /></span></del>
                    <span className="product-price" style={{fontSize:"19px",fontWeight:"bold",color:"rgb(149, 6, 13)",padding:"0px 20px"}}>Rs {res.Product_Price_Discounted} <br /> / Day</span> 
                  </div>
                  :
                  <span className="product-price" style={{fontSize:"19px",fontWeight:"bold",color:"rgb(149, 6, 13)",padding:"0px 20px"}}>                  Rs {(res.Product_Price)} <br />
             / Day
                  </span>            
                                    }
                                  </div>
                              <div>
                              <button class="animated-button8" style={{marginBottom:"0px"}}>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  With Driver
</button>
                              </div>

                             
                            </div>
                           
                            </div>
                            </div>
                            <div style={{display :"flex",flexDirection : "row",justifyContent :"space-between"}}>
                            <div>
                                <div style={{display:"flex",margin:"0px 0px 15px 0px"}}>
                                <img src={icon3} alt="Pak Dream" style={{height:"16px",width:"20px",color: "#95060D",fontSize : "15",marginRight  :"10px"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black",textAlign:"left"}}> ( 10hrs/day )
                                {/* <p style={{fontSize:11,fontWeight :"600",color:"black",textAlign:"left"}}>With Driver (10hrs/day) */}
                                </p>
                            </div>
                                <div style={{display:"flex",}}>
                                <img src={icon4} alt="Pak Dream" style={{height:"19px",width:"20px",color: "#95060D",fontSize : "15",marginRight  :"10px"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>Overtime: PKR {res.Overtime}/hr</p>
                            </div>
                            </div>
                            <div>
                                <div style={{display:"flex",marginTop:"40px"}}>
                                <div style={{display:"flex",marginRight: "28px"}}>
                                <ReceiptIcon style={{height:"19px",width:"20px",color: "rgb(205 112 112)",fontSize : "18",marginRight  :"10px"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>Without Tax</p>
                            </div>
                                {/* <button class="animated-button8">
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  With Driver
</button> */}
                                {/* {res.Product_Price_Discounted ? 
                  <><span className="product-price" style={{fontSize:"14px",fontWeight:"bold",color:"black"}}>Rs {res.Product_Price_Discounted} <br /> / Day</span></>
                  :
                  <span  style={{fontSize:"14px",fontWeight:"bold",color:"black"}}>
                  Rs {(res.Product_Price)} <br />
             / Day
                  </span>            
                                    } */}
                            </div>
                            </div>
                            </div>
                        







                                  {/* <hr style={{margin:"0px"}}/>           */}
                                  {/* { res && res.luggage &&  res.luggage > 0 ?
                            <div style={{display :"flex",flexDirection : "row",justifyContent :"space-between"}}>
                            <div>
                                <div style={{display:"flex",}}>
                                <DirectionsCarIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.doors && res.doors} Doors</p>
                            </div>
                                <div style={{display:"flex",}}>
                                <MeetingRoomIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.petrol && res.petrol}</p>
                            </div>
                            </div>
                            <div>
                                <div style={{display:"flex",}}>
                                <WhatshotIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.cc && res.cc} cc</p>
                            </div>
                                <div style={{display:"flex",}}>
                                <LocalMallIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.luggage && res.luggage} Lugg</p>
                            </div> 
                            </div>
                            <div>
                                <div style={{display:"flex",}}>
                                <ReceiptIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.ac && res.ac === "yes" ? "Ac" : "No AC"}</p>
                            </div>
                            <div style={{display:"flex",}}>
                                <SupervisorAccountIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.person && res.person} </p>
                            </div>
                            </div>
                            </div>
                            :""           
                      } */}
                      {/* <hr  style={{margin:"0px"}}/>
                    <div style={{display:"flex",marginTop:"10px"}}>
                         <h3 className="product-title"   style={{maxWidth:"100%",maxHeight : "59px",overflow : "hidden",textAlign:"left"}}>Earn upto 5% Off  </h3>
                         
                        </div>  */}
                      {/* <hr  style={{margin:"0px"}}/>               */}
                                  <div className="product-action position-relative visible" style={{display:"flex",justifyContent:"space-between",marginTop:"10px"}}>
                                    {/* {res.Product_Price_Discounted ? 
                    <><span className="product-price" style={{fontSize:"14px",fontWeight:"bold",color:"black"}}>Rs {res.Product_Price_Discounted} <br /> / Day</span></>
                    :
                    <span  style={{fontSize:"14px",fontWeight:"bold",color:"black"}}>
                    Rs {(res.Product_Price)} <br />
                / Day
                    </span>            
                                        }  */}
                                    <span className="shadow-none" style={{borderRadius:"15px",maxWidth:"150px",color:"white",background :"rgb(87 1 6)",padding :"10px 15px",color  :"white",fontSize:"12px",fontWeight:"bold"}} ><a href="tel:+923332221927" style={{color  :"white"}}>Call Now</a> </span>
                                    <span className="shadow-none" style={{borderRadius:"15px",maxWidth:"150px",color:"white",background :"rgb(87 1 6)",padding :"10px 15px",color  :"white",fontSize:"12px",fontWeight:"bold",cursor:"pointer"}} onClick={()=>addtocartproduct([res])}>Book Now</span>
                                </div>     
                                </div>
                              </div>
                            </div>
       )
    })
    :
                    Subproduct.map((res,i)=>{
                        return(
      <div className="col-12 col-md-6 col-lg-6 col-xl-4">
                              <div className="product product-7 text-center" style={{height : "auto",
    border: "2px solid rgb(149, 6, 13)",
    borderRadius: "20px",
    padding: "10px 0px",
    margin: "11px"}}>
                                <figure className="product-media">
                                  <Link  to={"/"+res.Product_Name_Full}>
                                    <img src={res.Product_Image_Upload} alt="Product image" className="product-image" />
                                  </Link>
                                  <div className="product-action-vertical">
                                    <a className="btn-product-icon btn-wishlist btn-expandable"  onClick={()=>addtocartproduct8([res])}><span>add to wishlist</span></a>
                                    <Link to={"/"+res.Product_Name_Full} className="btn-product-icon btn-quickview" title="Quick view"><span>Quick view</span></Link>
                                  </div>{/* End .product-action-vertical */}
                                </figure>{/* End .product-media */}
                                <div className="product-body">
                                  <div className="product-cat">
                                  </div>{/* End .product-cat */}
                                  <h3 className="product-title"   style={{maxWidth:"100%",maxHeight : "59px",overflow : "hidden",textAlign:"left"}}><Link to={"/"+res.Product_Name_Full}>{res.Product_Name}</Link></h3>{/* End .product-title */}


                            <div style={{display :"flex",flexDirection : "row",justifyContent :"space-between"}}>
                            <div>
                                <div style={{display:"flex",margin:"0px 0px 15px 0px"}}>
                                <img src={icon1} alt="Pak Dream" style={{height:"23px",width:"20px",color: "#95060D",fontSize : "15",marginRight  :"10px"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.person && res.person } Seats</p>
                            </div>
                                <div style={{display:"flex",}}>
                                <img src={icon2} alt="Pak Dream" style={{height:"23px",width:"20px",color: "#95060D",fontSize : "15",marginRight  :"10px"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>Automatic</p>
                            </div>
                            </div>
                            <div>
                               
                                <div style={{display:"flex",flexDirection:"column"}}>
                                  <div>

                 
                  <span className="product-price" style={{fontSize:"19px",fontWeight:"bold",color:"rgb(149, 6, 13)",padding:"0px 20px"}}>                  Rs {(res.OutCity)} <br />
             / Day
                  </span>            
                                  
                                  </div>
                              <div>
                              <button class="animated-button8" style={{marginBottom:"0px"}}>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  With Driver
</button>
                              </div>

                             
                            </div>
                           
                            </div>
                            </div>
                            <div style={{display :"flex",flexDirection : "row",justifyContent :"space-between"}}>
                            <div>
                                <div style={{display:"flex",margin:"0px 0px 15px 0px"}}>
                                <img src={icon3} alt="Pak Dream" style={{height:"16px",width:"20px",color: "#95060D",fontSize : "15",marginRight  :"10px"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black",textAlign:"left"}}> ( 24hrs/day )
                                {/* <p style={{fontSize:11,fontWeight :"600",color:"black",textAlign:"left"}}>With Driver (10hrs/day) */}
                                </p>
                            </div>
                                <div style={{display:"flex",}}>
                                <ReceiptIcon style={{height:"19px",width:"20px",color: "rgb(205 112 112)",fontSize : "15",marginRight  :"10px"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>Without Tax</p>
                            </div>
                            </div>
                            <div>
                                <div style={{display:"flex",marginTop:"20px"}}>
                                {/* <button class="animated-button8">
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  With Driver
</button> */}
                                {/* {res.Product_Price_Discounted ? 
                  <><span className="product-price" style={{fontSize:"14px",fontWeight:"bold",color:"black"}}>Rs {res.Product_Price_Discounted} <br /> / Day</span></>
                  :
                  <span  style={{fontSize:"14px",fontWeight:"bold",color:"black"}}>
                  Rs {(res.Product_Price)} <br />
             / Day
                  </span>            
                                    } */}
                            </div>
                            </div>
                            </div>
                        







                                  {/* <hr style={{margin:"0px"}}/>           */}
                                  {/* { res && res.luggage &&  res.luggage > 0 ?
                            <div style={{display :"flex",flexDirection : "row",justifyContent :"space-between"}}>
                            <div>
                                <div style={{display:"flex",}}>
                                <DirectionsCarIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.doors && res.doors} Doors</p>
                            </div>
                                <div style={{display:"flex",}}>
                                <MeetingRoomIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.petrol && res.petrol}</p>
                            </div>
                            </div>
                            <div>
                                <div style={{display:"flex",}}>
                                <WhatshotIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.cc && res.cc} cc</p>
                            </div>
                                <div style={{display:"flex",}}>
                                <LocalMallIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.luggage && res.luggage} Lugg</p>
                            </div> 
                            </div>
                            <div>
                                <div style={{display:"flex",}}>
                                <ReceiptIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.ac && res.ac === "yes" ? "Ac" : "No AC"}</p>
                            </div>
                            <div style={{display:"flex",}}>
                                <SupervisorAccountIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.person && res.person} </p>
                            </div>
                            </div>
                            </div>
                            :""           
                      } */}
                      {/* <hr  style={{margin:"0px"}}/>
                    <div style={{display:"flex",marginTop:"10px"}}>
                         <h3 className="product-title"   style={{maxWidth:"100%",maxHeight : "59px",overflow : "hidden",textAlign:"left"}}>Earn upto 5% Off  </h3>
                         
                        </div>  */}
                      {/* <hr  style={{margin:"0px"}}/>               */}
                                  <div className="product-action position-relative visible" style={{display:"flex",justifyContent:"space-between",marginTop:"10px"}}>
                                    {/* {res.Product_Price_Discounted ? 
                    <><span className="product-price" style={{fontSize:"14px",fontWeight:"bold",color:"black"}}>Rs {res.Product_Price_Discounted} <br /> / Day</span></>
                    :
                    <span  style={{fontSize:"14px",fontWeight:"bold",color:"black"}}>
                    Rs {(res.Product_Price)} <br />
                / Day
                    </span>            
                                        }  */}
                                    <span className="shadow-none" style={{borderRadius:"15px",maxWidth:"150px",color:"white",background :"rgb(87 1 6)",padding :"10px 15px",color  :"white",fontSize:"12px",fontWeight:"bold"}} ><a href="tel:+923332221927" style={{color  :"white"}}>Call Now</a> </span>
                                    <span className="shadow-none" style={{borderRadius:"15px",maxWidth:"150px",color:"white",background :"rgb(87 1 6)",padding :"10px 15px",color  :"white",fontSize:"12px",fontWeight:"bold",cursor:"pointer"}} onClick={()=>addtocartproduct([res])}>Book Now</span>
                                </div>     
                                </div>
                              </div>
                            </div>
       )
    })
}
      
<div className='container' style={{margin : "30px 10px"}}>
            <div className='row'  >
            <div className="col-10 col-lg-10  col-sm-12" style={{margin  :"auto"}}>
            <section>
    {/* <h2>Best Rent a Car Services in Karachi</h2> */}

    <h2>Experience Unmatched Elegance and Comfort with Our Luxury Car Rentals</h2>

<h3>Luxury Cars on Rent</h3>
<h4>Drive in Style with Pak Dream’s Luxury Car Rentals</h4>
<p style={{color:"black", fontWeight:"400"}}>
    Luxury cars embody sophistication, comfort, and exclusivity. Renting one of our premium vehicles offers you the chance to experience the highest levels of luxury without the long-term financial commitment. At Pak Dream Rent A Car, we make it easy for you to enjoy a world-class driving experience at an affordable price.
</p>

<h4>Why Rent a Luxury Car from Pak Dream?</h4>
<p style={{color:"black", fontWeight:"400"}}>
    When you rent a luxury car from Pak Dream, you're not just getting a high-end vehicle—you're indulging in an unforgettable experience. Our fleet features top-tier brands such as Mercedes Benz and Audi, ensuring that you have access to only the finest vehicles. We offer the latest models, meticulously maintained and delivered in pristine condition, inside and out.
</p>

<h4>Unmatched Service and Attention to Detail</h4>
<p style={{color:"black", fontWeight:"400"}}>
    We believe that renting a luxury car should be more than just a transaction—it should be an experience. From the moment you book your car to the moment you return it, Pak Dream’s dedicated team is here to provide exceptional service. Our professionals have years of automotive expertise and are committed to making sure every detail of your rental is taken care of. Whether you’re attending a special event or taking a luxury road trip, we ensure that your vehicle arrives on time and in perfect condition.
</p>

<h4>Affordable Luxury with Flexible Pricing</h4>
<p style={{color:"black", fontWeight:"400"}}>
    At Pak Dream, we understand that everyone deserves to enjoy luxury at an affordable price. Our competitive rental rates make it easier for you to experience high-end cars without exceeding your budget. We offer flexible daily and weekly rental packages to fit your needs, so you can drive your dream car without breaking the bank. And, if you prefer, we’ll even deliver your luxury car with a professional driver right to your doorstep in major cities like Karachi, Lahore, and Islamabad.
</p>

<h4>Why Choose Pak Dream Rent A Car for Luxury Rentals?</h4>
<p style={{color:"black", fontWeight:"400"}}>
    Pak Dream is widely recognized as one of the top luxury car rental services in Pakistan, serving not only Karachi but also Lahore, Islamabad, and other major cities. Here’s why you should choose us for your next luxury car rental:
</p>
<ul style={{color:"black", fontWeight:"400"}}>
    <li><strong>No Hidden Charges:</strong> Transparency is key. The price you see online includes everything—insurance, with only VAT and fuel charges added.</li>
    <li><strong>Flexible Pricing Plans:</strong> We offer customizable rental packages that allow you to balance the time and kilometers based on your travel requirements. You can modify your plan at any time.</li>
    <li><strong>24/7 Support:</strong> Our team is available around the clock, providing 24/7 assistance for any concerns or issues that may arise. In the event of a breakdown, we’ll send a replacement car to get you back on the road quickly.</li>
</ul>

<h2>Book Your Luxury Car Today with Pak Dream Rent A Car</h2>
<p style={{color:"black", fontWeight:"400"}}>
    If you’ve ever dreamed of driving a high-end car, Pak Dream Rent A Car is here to turn that dream into reality. Our fleet of luxury vehicles, paired with our dedicated service and competitive pricing, ensures that you can enjoy the car of your dreams without compromise. Book your luxury ride today and experience the ultimate in style, comfort, and convenience!
</p>


<h2>Frequently Asked Questions (FAQs)</h2>

<div class="faq-content">
    <div class="faq-question">
        <input id="q40" type="checkbox" class="panel"/>
        <div class="plus">+</div>
        <label for="q40" class="panel-title">What brands are available for Luxury Car Rentals?</label>
        <div class="panel-content">
            <p>At Pak Dream Rent A Car, we offer a premium selection of luxury cars for rent, including top-tier brands such as:</p>
            <ul>
                <li>Mercedes Benz</li>
                <li>Audi</li>
            </ul>
            <p>These luxury vehicles provide the highest levels of comfort, style, and performance for any special occasion or event.</p>
        </div>
    </div>

    <div class="faq-question">
        <input id="q41" type="checkbox" class="panel"/>
        <div class="plus">+</div>
        <label for="q41" class="panel-title">Can the luxury cars be rented without a driver?</label>
        <div class="panel-content">
            <p>Currently, our luxury cars are available only with a driver. We provide a professional chauffeur service for a seamless, stress-free experience.</p>
        </div>
    </div>

    <div class="faq-question">
        <input id="q42" type="checkbox" class="panel"/>
        <div class="plus">+</div>
        <label for="q42" class="panel-title">What is the minimum rental duration for luxury cars?</label>
        <div class="panel-content">
            <p>The minimum rental duration for a luxury car is 10 hours. However, if you need the car for longer, additional hours can be added at an extra charge for overtime.</p>
        </div>
    </div>

    <div class="faq-question">
        <input id="q43" type="checkbox" class="panel"/>
        <div class="plus">+</div>
        <label for="q43" class="panel-title">What are the benefits of renting a luxury car with a driver?</label>
        <div class="panel-content">
            <p>Renting a luxury car with a driver offers several key benefits:</p>
            <ul>
                <li><strong>Convenience and comfort:</strong> Enjoy being chauffeured around without the hassle of driving or parking.</li>
                <li><strong>Stylish travel:</strong> Arrive in style at your destination, perfect for weddings, corporate events, and other special occasions.</li>
                <li><strong>Stress-free experience:</strong> Focus on your journey while your professional driver takes care of the road.</li>
            </ul>
        </div>
    </div>

    <div class="faq-question">
        <input id="q44" type="checkbox" class="panel"/>
        <div class="plus">+</div>
        <label for="q44" class="panel-title">What qualifications do the drivers have?</label>
        <div class="panel-content">
            <p>Our drivers are experienced professionals with valid licenses, a clean driving record, and a deep knowledge of the local area. They are committed to providing exceptional customer service and ensuring a smooth and luxurious experience.</p>
        </div>
    </div>

    <div class="faq-question">
        <input id="q45" type="checkbox" class="panel"/>
        <div class="plus">+</div>
        <label for="q45" class="panel-title">How far in advance do I need to book a luxury car rental with a driver?</label>
        <div class="panel-content">
            <p>To ensure availability, it is recommended to book your luxury car rental at least a few weeks in advance, especially during peak seasons or for special events like weddings, conferences, or VIP gatherings.</p>
        </div>
    </div>
</div>
{/* 
<ol style={{color:"black", fontWeight:"400"}}>
    <li><strong>What brands are available for Luxury Car Rentals?</strong>
        <p>At Pak Dream Rent A Car, we offer a premium selection of luxury cars for rent, including top-tier brands such as:</p>
        <ul>
            <li>Mercedes Benz</li>
            <li>Audi</li>
        </ul>
        <p>These luxury vehicles provide the highest levels of comfort, style, and performance for any special occasion or event.</p>
    </li>
    <li><strong>Can the luxury cars be rented without a driver?</strong>
        <p>Currently, our luxury cars are available only with a driver. We provide a professional chauffeur service for a seamless, stress-free experience.</p>
    </li>
    <li><strong>What is the minimum rental duration for luxury cars?</strong>
        <p>The minimum rental duration for a luxury car is 10 hours. However, if you need the car for longer, additional hours can be added at an extra charge for overtime.</p>
    </li>
    <li><strong>What are the benefits of renting a luxury car with a driver?</strong>
        <p>Renting a luxury car with a driver offers several key benefits:</p>
        <ul>
            <li><strong>Convenience and comfort:</strong> Enjoy being chauffeured around without the hassle of driving or parking.</li>
            <li><strong>Stylish travel:</strong> Arrive in style at your destination, perfect for weddings, corporate events, and other special occasions.</li>
            <li><strong>Stress-free experience:</strong> Focus on your journey while your professional driver takes care of the road.</li>
        </ul>
    </li>
    <li><strong>What qualifications do the drivers have?</strong>
        <p>Our drivers are experienced professionals with valid licenses, a clean driving record, and a deep knowledge of the local area. They are committed to providing exceptional customer service and ensuring a smooth and luxurious experience.</p>
    </li>
    <li><strong>How far in advance do I need to book a luxury car rental with a driver?</strong>
        <p>To ensure availability, it is recommended to book your luxury car rental at least a few weeks in advance, especially during peak seasons or for special events like weddings, conferences, or VIP gatherings.</p>
    </li>
</ol> */}

</section>


    </div>
            </div>
            </div>
         

                      
                    </div>{/* End .row */}
                  </div>{/* End .products */}
                  <nav aria-label="Page navigation">
                    <ul className="pagination justify-content-center">
                      <li className="page-item disabled">
                        <a className="page-link page-link-prev" href="#" aria-label="Previous" tabIndex={-1} aria-disabled="true">
                          <span aria-hidden="true"><i className="icon-long-arrow-left" /></span>Prev
                        </a>
                      </li>
                      <li className="page-item active" aria-current="page"><a className="page-link" href="#">1</a></li>
                      {/* <li className="page-item"><a className="page-link" href="#">2</a></li>
                      <li className="page-item"><a className="page-link" href="#">3</a></li>
                      <li className="page-item-total">of 6</li> */}
                      <li className="page-item">
                        <a className="page-link page-link-next" href="#" aria-label="Next">
                          Next <span aria-hidden="true"><i className="icon-long-arrow-right" /></span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>{/* End .col-lg-9 */}
                
              </div>{/* End .row */}
            </div>{/* End .container */}
          </div>{/* End .page-content */}
        </main>{/* End .main */}




</>
        )
    }



export default SubCategoriesProduct;