import React, { useState , useEffect } from 'react';
import { Link } from 'react-router-dom';
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import swal from 'sweetalert';

import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import ReceiptIcon from '@material-ui/icons/Receipt';
import LocalMallIcon from '@material-ui/icons/LocalMall';



import pay from '../assets/images/pay.png';






import icon1 from '../assets/images/icon1 1.png';
import icon2 from '../assets/images/icon2 1.png';
import icon3 from '../assets/images/icon3 1.png';
import icon4 from '../assets/images/icon4 1.png';

import {Helmet} from "react-helmet";









const SubCategoriesProduct = (props)=> {
    const [CategoriesUrl , setCategoriesUrl] = useState("")
    const [Product , setProduct] = useState([])
    const [Product1 , setProduct1] = useState([])
    const [Product2 , setProduct2] = useState([])
    const [Categories , setCategories] = useState([])
    const [fal , setFal] = useState(false)
    const [fal1 , setFal1] = useState(false)
    const [CategoriesName , setCategoriesname] = useState({})
    const [SubCategories , setSubCategories] = useState([])
    const [SubClassCategories , setSubClassCategories] = useState([])
    const [subCate , setsubCate] = useState([])
    const [allproduct , setallproduct] = useState([])
    const [Subproduct , setSubproduct] = useState([])
    const [Subproduct1 , setSubproduct1] = useState([])
    const [SortedData , setSortedData] = useState([])
    const [SortedName , setSortedName] = useState([])
    const [SliderPhoto , setSliderPhoto] = useState([])
    const [DataPart2 , setDataPart2] = useState([])
    const [MainCatogories , setMainCatogories] = useState([])
    const [dispaluy53 , setdispaluy53] = useState(true)
    const [Cate1 , setCate1] = useState([])
    const [Cate2 , setCate2] = useState([])
    const [Cities , setCities] = useState(true)

    // categoriesName subcategories

    useEffect(() =>{

        window.scrollTo(0, 0)
        // console.log( props.match.params);
        let Categoriesname = props.match.params ;
        setCategoriesname(Categoriesname)

        // const Inter = setInterval(()=>{
            //  if (!JSON.parse(localStorage.getItem("CateProduct")) ){
            //  var data1 = JSON.parse(localStorage.getItem("Cate")) 

        // var Category =  props.match.url.split("/")
        // setCategoriesUrl(data1)
        // var Category1 =  CategoriesUrl.split("-").join(" ")
        fetch("https://pak-dream-back.vercel.app/AllProduct",{
                    method: "GET",
                    headers :  {
                    "Content-Type" : "application/json" , 
                } ,
                })
                .then(res7=>res7.json())
                .then(res8=>{
                    setallproduct(res8)
                    const NonActive = res8.filter((res9,i)=>{
                        // console.log(res9.status , res9.Product_Catagories , "Cars"  , res9.Product_Sub_Catagories , Categoriesname.subCate );
                        if(res9.status && res9.Product_Catagories === "Cars" && res9.vehicles === "Standard" ){
                            return res9 
                        }
                    })

                    setSubproduct(NonActive)
                    console.log(res8)
                })

        
       

                // fetch("https://pak-dream-back.vercel.app/AllProduct",{
                //     method: "GET",
                //     headers :  {
                //     "Content-Type" : "application/json" , 
                // } ,
                // })
                // .then(res7=>res7.json())
                // .then(res8=>{
                //     setallproduct(res8)
                //     const NonActive = res8.filter((res9,i)=>{
                //         console.log(res9.status , res9.Product_Catagories , "Cars"  , res9.Product_Sub_Catagories , Categoriesname.subCate );
                //         if(res9.status && res9.Product_Catagories === "Cars"  ){
                //             return res9 
                //         }
                //     })

                //     setSubproduct(NonActive)
                //     console.log(NonActive)
                // })

        

      



},[])


    const setSortPrice1 = (e)=>{
      setdispaluy53(!dispaluy53)
      if(Subproduct.length !== 0){

        SortedName.push(e)
        console.log(e,Subproduct);
        if (e === "Low"){
            const Pro6 = Subproduct.sort(function (a, b) {
                    return parseFloat(a.Product_Price) - parseFloat(b.Product_Price)  
                  });
                  console.log(Pro6);
                  let reversed_array = [];
                  Pro6.forEach((element) => {
                    reversed_array.unshift(element);
                });
                  console.log(Pro6,reversed_array)
                  setSubproduct(reversed_array)
                  setSubproduct(Pro6)
                  setdispaluy53(!dispaluy53)
                  setdispaluy53(!dispaluy53)
    
        }
        if (e === "High"){
            const Pro6 = Subproduct.sort((a, b)=> {
                    return parseFloat(b.Product_Price) - parseFloat(a.Product_Price)  
                    
                });
              //   let reversed_array = [];
              //   Pro6.forEach((element) => {
              //     reversed_array.unshift(element);
              // });
              //   console.log(Pro6,reversed_array)
                setSubproduct(Pro6)
                setdispaluy53(!dispaluy53)
                setdispaluy53(!dispaluy53)

        }
        if (e === "A"){
            const Pro6 = Subproduct.sort((a, b)=> {
                var nameA = a.Product_Name.toUpperCase(); 
                var nameB = b.Product_Name.toUpperCase(); 
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
    
                return 0;
                });
                console.log(Pro6);
                setSubproduct(Pro6)
                
              }
              
              setSubproduct1([])
              setdispaluy53(!dispaluy53)

            }
            else{
        
                SortedName.push(e)
                console.log(e);
                if (e === "Low"){
                    const Pro6 = Subproduct.sort((a, b)=> {
                            return parseFloat(b.Product_Price) - parseFloat(a.Product_Price)  
                            
                        });
                        setSubproduct1(Pro6)
            
                }
                if (e === "High"){
                    const Pro6 = Subproduct.sort((a, b)=> {
                            return parseFloat(b.Product_Price) - parseFloat(a.Product_Price)  
                            
                        });
                        setSubproduct1(Pro6.reverse())
            
                }
                if (e === "A"){
                    const Pro6 = Subproduct.sort((a, b)=> {
                        var nameA = a.Product_Name.toUpperCase(); 
                        var nameB = b.Product_Name.toUpperCase(); 
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }
            
                        return 0;
                        });
                        console.log(Pro6);
                        setSubproduct1(Pro6)
                        
                      }
                      
                      
                      setSubproduct([])
                    }
                    setdispaluy53(!dispaluy53)

                  }
  

  
const addtocartproduct = (productDetail) =>{
  swal("Update!", "Your Item Add in Cart!", "success");

      
  // document.getElementById("myDi").style.visibility = "visible"
  // setTimeout(()=>{
  // document.getElementById("myDi").style.visibility = "hidden"

  // },1000)
  console.log(productDetail)
  if (productDetail[0].Product_Price_Discounted === null){
  const data =  {...productDetail[0] ,
      Percentage : 0,
      DeliveryStatus : "Pending",
      Pieces : 1 ,
      Total_Product_Price  : productDetail[0].Product_Price * 1 }
  var data1 = JSON.parse(localStorage.getItem("Cart")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id ){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
  return {...item,
      Pieces : 1 + item.Pieces,
      Total_Product_Price  : (productDetail[0].Product_Price * 1 )+ item.Total_Product_Price}
  }
  else{
  console.log("double not match");
  return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Cart")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Cart" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Cart" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Cart" , JSON.stringify([data]) )

  }

  }
  else if (productDetail[0].Product_Price_Discounted){
  const data =  {...productDetail[0] ,
      Percentage : 0,
      DeliveryStatus : "Pending",
      Pieces : 1 ,
      Total_Product_Price  : productDetail[0].Product_Price_Discounted * 1 }
  var data1 = JSON.parse(localStorage.getItem("Cart")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id ){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
  return {...item,
      Pieces : 1 + item.Pieces,
      Total_Product_Price  : (productDetail[0].Product_Price_Discounted * 1 )+ item.Total_Product_Price}
  }
  else{
  console.log("double not match");
  return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Cart")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Cart" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Cart" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Cart" , JSON.stringify([data]) )

  }

  }
  else if (productDetail[0].Size_Discounted[0]){
      
          const data =  {...productDetail[0] ,
              Percentage : 0,
              DeliveryStatus : "Pending",
              Pieces : 1 ,
              Size : productDetail[0].Size[0],
              Product_Price_Discounted : productDetail[0].Size_Discounted[0],
              Total_Product_Price  : productDetail[0].Size_Discounted[0] * 1 }
  var data1 = JSON.parse(localStorage.getItem("Cart")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
      return {...item,
          Pieces : 1 + item.Pieces,
          Total_Product_Price  : (productDetail[0].Size_Discounted[0] * 1 )+ item.Total_Product_Price}
  }
  else{
      console.log("double not match");
      return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Cart")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Cart" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Cart" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Cart" , JSON.stringify([data]) )

  }

  }
  else{




          const data =  {...productDetail[0] ,
              Percentage : 0,
              DeliveryStatus : "Pending",
              Pieces : 1 ,
              Size : productDetail[0].Size[0],
              Product_Price_Discounted : productDetail[0].Price[0],
              Total_Product_Price  : productDetail[0].Price[0] * 1 }
  var data1 = JSON.parse(localStorage.getItem("Cart")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
      return {...item,
          Pieces : 1 + item.Pieces,
          Total_Product_Price  : (productDetail[0].Price[0] * 1 )+ item.Total_Product_Price}
  }
  else{
      console.log("double not match");
      return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Cart")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Cart" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Cart" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Cart" , JSON.stringify([data]) )

  }


  }


      

}


const addtocartproduct8 = (productDetail) =>{
    swal("Update!", "Item Save!", "success");
  
        
    // document.getElementById("myDi").style.visibility = "visible"
    // setTimeout(()=>{
    // document.getElementById("myDi").style.visibility = "hidden"
  
    // },1000)
    console.log(productDetail)
    if (productDetail[0].Product_Price_Discounted === null){
    const data =  {...productDetail[0] ,
        Percentage : 0,
        DeliveryStatus : "Pending",
        Pieces : 1 ,
        Total_Product_Price  : productDetail[0].Product_Price * 1 }
    var data1 = JSON.parse(localStorage.getItem("Fav")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id ){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
    return {...item,
        Pieces : 1 + item.Pieces,
        Total_Product_Price  : (productDetail[0].Product_Price * 1 )+ item.Total_Product_Price}
    }
    else{
    console.log("double not match");
    return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Fav")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Fav" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Fav" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Fav" , JSON.stringify([data]) )
  
    }
  
    }
    else if (productDetail[0].Product_Price_Discounted){
    const data =  {...productDetail[0] ,
        Percentage : 0,
        DeliveryStatus : "Pending",
        Pieces : 1 ,
        Total_Product_Price  : productDetail[0].Product_Price_Discounted * 1 }
    var data1 = JSON.parse(localStorage.getItem("Fav")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id ){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
    return {...item,
        Pieces : 1 + item.Pieces,
        Total_Product_Price  : (productDetail[0].Product_Price_Discounted * 1 )+ item.Total_Product_Price}
    }
    else{
    console.log("double not match");
    return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Fav")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Fav" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Fav" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Fav" , JSON.stringify([data]) )
  
    }
  
    }
    else if (productDetail[0].Size_Discounted[0]){
        
            const data =  {...productDetail[0] ,
                Percentage : 0,
                DeliveryStatus : "Pending",
                Pieces : 1 ,
                Size : productDetail[0].Size[0],
                Product_Price_Discounted : productDetail[0].Size_Discounted[0],
                Total_Product_Price  : productDetail[0].Size_Discounted[0] * 1 }
    var data1 = JSON.parse(localStorage.getItem("Fav")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
        return {...item,
            Pieces : 1 + item.Pieces,
            Total_Product_Price  : (productDetail[0].Size_Discounted[0] * 1 )+ item.Total_Product_Price}
    }
    else{
        console.log("double not match");
        return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Fav")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Fav" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Fav" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Fav" , JSON.stringify([data]) )
  
    }
  
    }
    else{
  
  
  
  
            const data =  {...productDetail[0] ,
                Percentage : 0,
                DeliveryStatus : "Pending",
                Pieces : 1 ,
                Size : productDetail[0].Size[0],
                Product_Price_Discounted : productDetail[0].Price[0],
                Total_Product_Price  : productDetail[0].Price[0] * 1 }
    var data1 = JSON.parse(localStorage.getItem("Fav")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
        return {...item,
            Pieces : 1 + item.Pieces,
            Total_Product_Price  : (productDetail[0].Price[0] * 1 )+ item.Total_Product_Price}
    }
    else{
        console.log("double not match");
        return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Fav")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Fav" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Fav" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Fav" , JSON.stringify([data]) )
  
    }
  
  
    }
  
  
        
  
  }
  
        return (
       




<>
           <Helmet>
                <link rel="canonical" href="https://pakdreamrentacar.com/standard-cars" />
                <meta name="description" content="Rent a standard car in Karachi and experience comfort and style with Pak Dream Rent a Car. Choose from our collection of premium standard cars for rent and enjoy the most comfortable driving experience" />
            </Helmet>
          <main className="main">
          <div className="text-center">
            <div className="container">
              <h1 className="page-title" style={{fontSize:"27px",fontWeight:"bold", margin:"0px 10px",padding:0,textTransform:"uppercase"}}>Standard Cars on Rent
              <span></span></h1>
              <p>
              When it comes to car rentals, standard cars are among the most popular choices for travelers, and at Pak Dream Rent A Car, we are proud to offer a wide range of reliable, affordable, and fuel-efficient standard vehicles. Whether you're heading out for a business trip, a family vacation, or a weekend getaway, renting a standard car from Pak Dream ensures a smooth and enjoyable journey.

              </p>
            </div>{/* End .container */}
          </div>{/* End .page-header */}
<hr  style={{margin:"0px"}}/>
          <div className="page-content">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="toolbox">
                    <div className="toolbox-left" style={{width:"100%"}}>
                      <div className="toolbox-info" style={{width:"100%"}}>
                     <div className='dskjffkj' style={{width:"100%",display:"flex",color : "black"}}>
                       <div className='sfsdf' style={{display:"flex",flexDirection :"column",width:"140px",marginRight : "100px"}}>
                         <span style={{color : "black",fontSize:"17px"}}>Price </span>
                         <span style={{marginRight : "20px",width:"140px",color : "black",fontSize:"15px"}} > Rs : 4500 to 20000 </span>
                         <div style={{display:"flex"}}>

                         <input className='gklfgkl' type="range" name="" id="" value={0} min="0" max="9500" style={{color:"blue",color : "black",fontSize:"17px",padding:"0px",width:"60px"}}/>
                         <input className='gklfgkl' type="range" name="" id="" value={19500} min="9500" max="20000" style={{color:"blue",color : "black",fontSize:"17px",padding:"0px",width:"60px"}}/>
                         </div>
                        </div>
                        <div>
                          <span style={{color : "black",fontSize:"18px"}}>{Subproduct.length} <b>Cars</b>  founds</span>
                        </div>
                        </div> 
                        
                      </div>{/* End .toolbox-info */}
                    </div>{/* End .toolbox-left */}
                    <div className="toolbox-right" style={{width:"100%",justifyContent:"right",alignItems:"flex-end"}}>
                      <div className="toolbox-sort">
                        <label htmlFor="sortby">Sort by:</label>
                        <div className="select-custom">
                          <select name="sortby" id="sortby" className="form-control">
                            <option value="popularity" selected="selected">Most Popular</option>
                            <option value="Low" onClick={()=>setSortPrice1("Low")}>Low → High</option>
                          <option value="High" onClick={()=>setSortPrice1("High")}>High → Low</option>
                          <option value="A" onClick={()=>setSortPrice1("A")}>A → Z</option>
                            <option value="rating">Most Rated</option>
                            <option value="date">Date</option>
                          </select>
                        </div>
                      </div>{/* End .toolbox-sort */}

                    </div>{/* End .toolbox-right */}
                  </div>{/* End .toolbox */}
                      <div style={{display:"flex",justifyContent:"space-evenly",color:"black",fontSize:"19px",fontWeight:"500"}}>

{/* <input className='gklfgkl' type="radio" name="" id="" value={0} min="0" max="9500" style={{color:"blue",color : "black",fontSize:"17px",padding:"0px",width:"60px"}}/>
<input className='gklfgkl' type="radio" name="" id="" value={19500} min="9500" max="20000" style={{color:"blue",color : "black",fontSize:"17px",padding:"0px",width:"60px"}}/> */}
<span>
 <input className='gklfgkl' type="radio" value={Cities} name="Cities1"  onClick={()=>setCities(true)}  style={{accentColor:"firebrick", backgroundColor:"#EFE5CE",border: "10px solid #EFE5CE",marginRight:"9px"}}/> In City
</span>
<span>
 <input className='gklfgkl' type="radio" value={Cities} name="Cities1"  onClick={()=>setCities(false)}   style={{accentColor:"firebrick", backgroundColor:"#EFE5CE",border: "10px solid #EFE5CE",marginRight:"9px"}}/> Out City

</span>
<span>
 <input className='gklfgkl' type="radio" value={Cities} name="Cities1"  onClick={()=>setCities(true)}   style={{accentColor:"firebrick", backgroundColor:"#EFE5CE",border: "10px solid #EFE5CE",marginRight:"9px"}}/> Short Rental

</span>

</div>  
                  <div className="products mb-3">
                    <div className="row justify-content-center">
                    {Cities ? 
                    Subproduct.map((res,i)=>{
                        return(
      <div className="col-12 col-md-6 col-lg-6 col-xl-4">
                              <div className="product product-7 text-center" style={{height : "auto",
    border: "2px solid rgb(149, 6, 13)",
    borderRadius: "20px",
    padding: "10px 0px",
    margin: "11px"}}>
                                <figure className="product-media">
                                  <Link  to={"/"+res.Product_Name_Full}>
                                    <img src={res.Product_Image_Upload} alt="Product image" className="product-image" />
                                  </Link>
                                  <div className="product-action-vertical">
                                    <a className="btn-product-icon btn-wishlist btn-expandable"  onClick={()=>addtocartproduct8([res])}><span>add to wishlist</span></a>
                                    <Link to={"/"+res.Product_Name_Full} className="btn-product-icon btn-quickview" title="Quick view"><span>Quick view</span></Link>
                                  </div>{/* End .product-action-vertical */}
                                </figure>{/* End .product-media */}
                                <div className="product-body">
                                  <div className="product-cat">
                                  </div>{/* End .product-cat */}
                                  <h3 className="product-title"   style={{maxWidth:"100%",maxHeight : "59px",overflow : "hidden",textAlign:"left"}}><Link to={"/"+res.Product_Name_Full}>{res.Product_Name}</Link></h3>{/* End .product-title */}


                            <div style={{display :"flex",flexDirection : "row",justifyContent :"space-between"}}>
                            <div>
                                <div style={{display:"flex",margin:"0px 0px 15px 0px"}}>
                                <img src={icon1} alt="Pak Dream" style={{height:"23px",width:"20px",color: "#95060D",fontSize : "15",marginRight  :"10px"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.person && res.person } Seats</p>
                            </div>
                                <div style={{display:"flex",}}>
                                <img src={icon2} alt="Pak Dream" style={{height:"23px",width:"20px",color: "#95060D",fontSize : "15",marginRight  :"10px"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>Automatic</p>
                            </div>
                            </div>
                            <div>
                               
                                <div style={{display:"flex",flexDirection:"column"}}>
                                  <div>

                                {res.Product_Price_Discounted ? 
                  <div style={{display:"flex"}}>
                  <del><span className="product-price" style={{fontSize:"13px",fontWeight:"bold",color:"black"}}>Rs {res.Product_Price} <br /></span></del>
                    <span className="product-price" style={{fontSize:"19px",fontWeight:"bold",color:"rgb(149, 6, 13)",padding:"0px 20px"}}>Rs {res.Product_Price_Discounted} <br /> / Day</span> 
                  </div>
                  :
                  <span className="product-price" style={{fontSize:"19px",fontWeight:"bold",color:"rgb(149, 6, 13)",padding:"0px 20px"}}>                  Rs {(res.Product_Price)} <br />
             / Day
                  </span>            
                                    }
                                  </div>
                              <div>
                              <button class="animated-button8" style={{marginBottom:"0px"}}>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  With Driver
</button>
                              </div>

                             
                            </div>
                           
                            </div>
                            </div>
                            <div style={{display :"flex",flexDirection : "row",justifyContent :"space-between"}}>
                            <div>
                                <div style={{display:"flex",margin:"0px 0px 15px 0px"}}>
                                <img src={icon3} alt="Pak Dream" style={{height:"16px",width:"20px",color: "#95060D",fontSize : "15",marginRight  :"10px"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black",textAlign:"left"}}> ( 10hrs/day )
                                {/* <p style={{fontSize:11,fontWeight :"600",color:"black",textAlign:"left"}}>With Driver (10hrs/day) */}
                                </p>
                            </div>
                                <div style={{display:"flex",}}>
                                <img src={icon4} alt="Pak Dream" style={{height:"19px",width:"20px",color: "#95060D",fontSize : "15",marginRight  :"10px"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>Overtime: PKR {res.Overtime}/hr</p>
                            </div>
                            </div>
                            <div>
                                <div style={{display:"flex",marginTop:"40px"}}>
                                <div style={{display:"flex",marginRight: "28px"}}>
                                <ReceiptIcon style={{height:"19px",width:"20px",color: "rgb(205 112 112)",fontSize : "18",marginRight  :"10px"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>Without Tax</p>
                            </div>
                                {/* <button class="animated-button8">
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  With Driver
</button> */}
                                {/* {res.Product_Price_Discounted ? 
                  <><span className="product-price" style={{fontSize:"14px",fontWeight:"bold",color:"black"}}>Rs {res.Product_Price_Discounted} <br /> / Day</span></>
                  :
                  <span  style={{fontSize:"14px",fontWeight:"bold",color:"black"}}>
                  Rs {(res.Product_Price)} <br />
             / Day
                  </span>            
                                    } */}
                            </div>
                            </div>
                            </div>
                        







                                  {/* <hr style={{margin:"0px"}}/>           */}
                                  {/* { res && res.luggage &&  res.luggage > 0 ?
                            <div style={{display :"flex",flexDirection : "row",justifyContent :"space-between"}}>
                            <div>
                                <div style={{display:"flex",}}>
                                <DirectionsCarIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.doors && res.doors} Doors</p>
                            </div>
                                <div style={{display:"flex",}}>
                                <MeetingRoomIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.petrol && res.petrol}</p>
                            </div>
                            </div>
                            <div>
                                <div style={{display:"flex",}}>
                                <WhatshotIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.cc && res.cc} cc</p>
                            </div>
                                <div style={{display:"flex",}}>
                                <LocalMallIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.luggage && res.luggage} Lugg</p>
                            </div> 
                            </div>
                            <div>
                                <div style={{display:"flex",}}>
                                <ReceiptIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.ac && res.ac === "yes" ? "Ac" : "No AC"}</p>
                            </div>
                            <div style={{display:"flex",}}>
                                <SupervisorAccountIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.person && res.person} </p>
                            </div>
                            </div>
                            </div>
                            :""           
                      } */}
                      {/* <hr  style={{margin:"0px"}}/>
                    <div style={{display:"flex",marginTop:"10px"}}>
                         <h3 className="product-title"   style={{maxWidth:"100%",maxHeight : "59px",overflow : "hidden",textAlign:"left"}}>Earn upto 5% Off  </h3>
                         
                        </div>  */}
                      {/* <hr  style={{margin:"0px"}}/>               */}
                                  <div className="product-action position-relative visible" style={{display:"flex",justifyContent:"space-between",marginTop:"10px"}}>
                                    {/* {res.Product_Price_Discounted ? 
                    <><span className="product-price" style={{fontSize:"14px",fontWeight:"bold",color:"black"}}>Rs {res.Product_Price_Discounted} <br /> / Day</span></>
                    :
                    <span  style={{fontSize:"14px",fontWeight:"bold",color:"black"}}>
                    Rs {(res.Product_Price)} <br />
                / Day
                    </span>            
                                        }  */}
                                    <span className="shadow-none" style={{borderRadius:"15px",maxWidth:"150px",color:"white",background :"rgb(87 1 6)",padding :"10px 15px",color  :"white",fontSize:"12px",fontWeight:"bold"}} ><a href="tel:+923332221927" style={{color  :"white"}}>Call Now</a> </span>
                                    <span className="shadow-none" style={{borderRadius:"15px",maxWidth:"150px",color:"white",background :"rgb(87 1 6)",padding :"10px 15px",color  :"white",fontSize:"12px",fontWeight:"bold",cursor:"pointer"}} onClick={()=>addtocartproduct([res])}>Book Now</span>
                                </div>     
                                </div>
                              </div>
                            </div>
       )
    })
    :
                    Subproduct.map((res,i)=>{
                        return(
      <div className="col-12 col-md-6 col-lg-6 col-xl-4">
                              <div className="product product-7 text-center" style={{height : "auto",
    border: "2px solid rgb(149, 6, 13)",
    borderRadius: "20px",
    padding: "10px 0px",
    margin: "11px"}}>
                                <figure className="product-media">
                                  <Link  to={"/"+res.Product_Name_Full}>
                                    <img src={res.Product_Image_Upload} alt="Product image" className="product-image" />
                                  </Link>
                                  <div className="product-action-vertical">
                                    <a className="btn-product-icon btn-wishlist btn-expandable"  onClick={()=>addtocartproduct8([res])}><span>add to wishlist</span></a>
                                    <Link to={"/"+res.Product_Name_Full} className="btn-product-icon btn-quickview" title="Quick view"><span>Quick view</span></Link>
                                  </div>{/* End .product-action-vertical */}
                                </figure>{/* End .product-media */}
                                <div className="product-body">
                                  <div className="product-cat">
                                  </div>{/* End .product-cat */}
                                  <h3 className="product-title"   style={{maxWidth:"100%",maxHeight : "59px",overflow : "hidden",textAlign:"left"}}><Link to={"/"+res.Product_Name_Full}>{res.Product_Name}</Link></h3>{/* End .product-title */}


                            <div style={{display :"flex",flexDirection : "row",justifyContent :"space-between"}}>
                            <div>
                                <div style={{display:"flex",margin:"0px 0px 15px 0px"}}>
                                <img src={icon1} alt="Pak Dream" style={{height:"23px",width:"20px",color: "#95060D",fontSize : "15",marginRight  :"10px"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.person && res.person } Seats</p>
                            </div>
                                <div style={{display:"flex",}}>
                                <img src={icon2} alt="Pak Dream" style={{height:"23px",width:"20px",color: "#95060D",fontSize : "15",marginRight  :"10px"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>Automatic</p>
                            </div>
                            </div>
                            <div>
                               
                                <div style={{display:"flex",flexDirection:"column"}}>
                                  <div>

                 
                  <span className="product-price" style={{fontSize:"19px",fontWeight:"bold",color:"rgb(149, 6, 13)",padding:"0px 20px"}}>                  Rs {(res.OutCity)} <br />
             / Day
                  </span>            
                                  
                                  </div>
                              <div>
                              <button class="animated-button8" style={{marginBottom:"0px"}}>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  With Driver
</button>
                              </div>

                             
                            </div>
                           
                            </div>
                            </div>
                            <div style={{display :"flex",flexDirection : "row",justifyContent :"space-between"}}>
                            <div>
                                <div style={{display:"flex",margin:"0px 0px 15px 0px"}}>
                                <img src={icon3} alt="Pak Dream" style={{height:"16px",width:"20px",color: "#95060D",fontSize : "15",marginRight  :"10px"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black",textAlign:"left"}}> ( 24hrs/day )
                                {/* <p style={{fontSize:11,fontWeight :"600",color:"black",textAlign:"left"}}>With Driver (10hrs/day) */}
                                </p>
                            </div>
                                <div style={{display:"flex",}}>
                                <ReceiptIcon style={{height:"19px",width:"20px",color: "rgb(205 112 112)",fontSize : "15",marginRight  :"10px"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>Without Tax</p>
                            </div>
                            </div>
                            <div>
                                <div style={{display:"flex",marginTop:"20px"}}>
                                {/* <button class="animated-button8">
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  With Driver
</button> */}
                                {/* {res.Product_Price_Discounted ? 
                  <><span className="product-price" style={{fontSize:"14px",fontWeight:"bold",color:"black"}}>Rs {res.Product_Price_Discounted} <br /> / Day</span></>
                  :
                  <span  style={{fontSize:"14px",fontWeight:"bold",color:"black"}}>
                  Rs {(res.Product_Price)} <br />
             / Day
                  </span>            
                                    } */}
                            </div>
                            </div>
                            </div>
                        







                                  {/* <hr style={{margin:"0px"}}/>           */}
                                  {/* { res && res.luggage &&  res.luggage > 0 ?
                            <div style={{display :"flex",flexDirection : "row",justifyContent :"space-between"}}>
                            <div>
                                <div style={{display:"flex",}}>
                                <DirectionsCarIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.doors && res.doors} Doors</p>
                            </div>
                                <div style={{display:"flex",}}>
                                <MeetingRoomIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.petrol && res.petrol}</p>
                            </div>
                            </div>
                            <div>
                                <div style={{display:"flex",}}>
                                <WhatshotIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.cc && res.cc} cc</p>
                            </div>
                                <div style={{display:"flex",}}>
                                <LocalMallIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.luggage && res.luggage} Lugg</p>
                            </div> 
                            </div>
                            <div>
                                <div style={{display:"flex",}}>
                                <ReceiptIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.ac && res.ac === "yes" ? "Ac" : "No AC"}</p>
                            </div>
                            <div style={{display:"flex",}}>
                                <SupervisorAccountIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.person && res.person} </p>
                            </div>
                            </div>
                            </div>
                            :""           
                      } */}
                      {/* <hr  style={{margin:"0px"}}/>
                    <div style={{display:"flex",marginTop:"10px"}}>
                         <h3 className="product-title"   style={{maxWidth:"100%",maxHeight : "59px",overflow : "hidden",textAlign:"left"}}>Earn upto 5% Off  </h3>
                         
                        </div>  */}
                      {/* <hr  style={{margin:"0px"}}/>               */}
                                  <div className="product-action position-relative visible" style={{display:"flex",justifyContent:"space-between",marginTop:"10px"}}>
                                    {/* {res.Product_Price_Discounted ? 
                    <><span className="product-price" style={{fontSize:"14px",fontWeight:"bold",color:"black"}}>Rs {res.Product_Price_Discounted} <br /> / Day</span></>
                    :
                    <span  style={{fontSize:"14px",fontWeight:"bold",color:"black"}}>
                    Rs {(res.Product_Price)} <br />
                / Day
                    </span>            
                                        }  */}
                                    <span className="shadow-none" style={{borderRadius:"15px",maxWidth:"150px",color:"white",background :"rgb(87 1 6)",padding :"10px 15px",color  :"white",fontSize:"12px",fontWeight:"bold"}} ><a href="tel:+923332221927" style={{color  :"white"}}>Call Now</a> </span>
                                    <span className="shadow-none" style={{borderRadius:"15px",maxWidth:"150px",color:"white",background :"rgb(87 1 6)",padding :"10px 15px",color  :"white",fontSize:"12px",fontWeight:"bold",cursor:"pointer"}} onClick={()=>addtocartproduct([res])}>Book Now</span>
                                </div>     
                                </div>
                              </div>
                            </div>
       )
    })
}
      
<div className='container' style={{margin : "30px 10px"}}>
            <div className='row'  >
            <div className="col-10 col-lg-10  col-sm-12" style={{margin  :"auto"}}>
            <section>
    {/* <h2>Best Rent a Car Services in Karachi</h2> */}

    {/* <h1>Quality and Affordable Rentals for Every Journey</h1> */}

<h2>Why Choose Pak Dream Rent A Car for Your Standard Car Rental Needs?</h2>
<p style={{color:"black", fontWeight:"400"}}>
    When it comes to car rentals, standard cars are among the most popular choices for travelers, and at Pak Dream Rent A Car, we are proud to offer a wide range of reliable, affordable, and fuel-efficient standard vehicles. Whether you're heading out for a business trip, a family vacation, or a weekend getaway, renting a standard car from Pak Dream ensures a smooth and enjoyable journey.
</p>

<h2>Top Benefits of Renting a Standard Car from Pak Dream Rent A Car</h2>
<ul style={{color:"black", fontWeight:"400"}}>
    <li><strong>Affordable and Budget-Friendly:</strong> Standard cars are an excellent choice for those seeking a wallet-friendly rental without sacrificing quality or reliability.</li>
    <li><strong>Fuel Efficiency:</strong> Save money on fuel with our fuel-efficient vehicles, designed to help you travel longer distances at a lower cost.</li>
    <li><strong>Convenience and Comfort:</strong> Whether you're navigating busy city streets or cruising on highways, our standard cars offer easy handling and comfort for all types of trips.</li>
    <li><strong>Versatile for All Occasions:</strong> From business trips to family vacations, our selection of standard cars, including sedans and compact models, provides ample space for luggage and a comfortable experience for passengers.</li>
</ul>

<h2>Features and Services at Pak Dream Rent A Car for Standard Car Rentals</h2>
<p style={{color:"black", fontWeight:"400"}}>
    We go above and beyond to ensure a hassle-free and enjoyable car rental experience. Here’s what you can expect when you rent a standard car with us:
</p>
<ul style={{color:"black", fontWeight:"400"}}>
    <li><strong>Flexible Rental Options:</strong> Choose from daily, weekly, or monthly rental packages that suit your travel schedule.</li>
    <li><strong>Easy Booking Process:</strong> Book your car online via our website or contact our customer service team for assistance.</li>
    <li><strong>24/7 Roadside Assistance:</strong> Should you face any issues during your rental period, our dedicated support team is available around the clock to assist with roadside assistance and car replacement if needed.</li>
    <li><strong>Short-Term Rental Service:</strong> Perfect for those who need a car for a short period, our flexible short-term rental options are available at multiple locations for your convenience.</li>
</ul>

<h2>Smart Tips for Renting a Standard Car from Pak Dream Rent A Car</h2>
<p style={{color:"black", fontWeight:"400"}}>
    To make your rental experience even smoother, here are some simple tips:
</p>
<ul style={{color:"black", fontWeight:"400"}}>
    <li><strong>Check for Discounts:</strong> Before booking, be sure to check for any special offers or promotions that may apply.</li>
    <li><strong>Understand the Terms:</strong> Carefully review the rental agreement, including fees, insurance coverage, and any potential penalties.</li>
    <li><strong>Inspect the Vehicle:</strong> Always inspect the car before pickup to ensure it meets your expectations and is in good condition.</li>
</ul>

<h2>Why Rent a Standard Car from Pak Dream Rent A Car?</h2>
<p style={{color:"black", fontWeight:"400"}}>
    Pak Dream Rent A Car is one of the leading car rental providers in Pakistan, proudly serving major cities including Karachi. We specialize in providing reliable cars and exceptional service, making us the top choice for travelers who want a stress-free car rental experience.
</p>

<h2>Reasons to Choose Pak Dream Rent A Car Over Others</h2>
<ul style={{color:"black", fontWeight:"400"}}>
    <li><strong>No Hidden Charges:</strong> What you see is what you get. Our transparent pricing ensures that the price quoted online is the price you pay—no surprises! The rental cost includes insurance, with only VAT and fuel charges added.</li>
    <li><strong>Flexible and Customizable Pricing Plans:</strong> We understand that every traveler has different needs. That’s why our rental packages are designed with flexibility in mind. You can choose the plan that best fits your requirements, and even adjust it as your plans change.</li>
    <li><strong>24/7 Customer Support:</strong> We’re always here to help. In case of any issues, our team is available 24/7 to assist with car replacements or any other needs that may arise during your rental period.</li>
</ul>
<h2>Book Your Standard Car Today with Pak Dream Rent A Car!</h2>
<p style={{color:"black", fontWeight:"400"}}>
    Whether you need a reliable vehicle for a quick business trip, a family road trip, or any other travel purpose, Pak Dream Rent A Car offers the best standard car rental options in Pakistan. With a fleet of top-quality cars, exceptional customer service, and competitive pricing, we ensure that your journey is smooth and enjoyable.
</p>
<p style={{color:"black", fontWeight:"400"}}>
    Ready to hit the road? Book your car today and experience hassle-free travel with Pak Dream Rent A Car!
</p>

<h2>Frequently Asked Questions (FAQs)</h2>

<div class="faq-content">
    <div class="faq-question">
        <input id="q35" type="checkbox" class="panel"/>
        <div class="plus">+</div>
        <label for="q35" class="panel-title">What type of transmission do the standard cars have?</label>
        <div class="panel-content">
            <p>All of our standard sedan cars come equipped with automatic transmissions, offering a smooth and easy driving experience for both city commutes and longer trips.</p>
        </div>
    </div>

    <div class="faq-question">
        <input id="q36" type="checkbox" class="panel"/>
        <div class="plus">+</div>
        <label for="q36" class="panel-title">Can I book a standard car for out-of-city travel?</label>
        <div class="panel-content">
            <p>Yes, our standard cars are available for out-of-city travel. You can book them with a driver, making it convenient for long-distance journeys to other cities.</p>
        </div>
    </div>

    <div class="faq-question">
        <input id="q37" type="checkbox" class="panel"/>
        <div class="plus">+</div>
        <label for="q37" class="panel-title">Are standard cars available for short-term rentals?</label>
        <div class="panel-content">
            <p>We offer short-term rentals for our Toyota Corolla GLI model, with a minimum rental period of 5 hours, and these rentals include a driver.</p>
        </div>
    </div>

    <div class="faq-question">
        <input id="q38" type="checkbox" class="panel"/>
        <div class="plus">+</div>
        <label for="q38" class="panel-title">Are standard cars available with or without a driver?</label>
        <div class="panel-content">
            <p>Yes, our standard cars can be rented with or without a driver. However, please note that the self-drive option is only available for rentals within Karachi at this time.</p>
        </div>
    </div>

    <div class="faq-question">
        <input id="q39" type="checkbox" class="panel"/>
        <div class="plus">+</div>
        <label for="q39" class="panel-title">What types of cars are available in the standard rental category?</label>
        <div class="panel-content">
            <p>In our standard rental fleet, we offer a selection of popular sedan models, including:</p>
            <ul>
                <li>Honda Civic</li>
                <li>Honda City</li>
                <li>Toyota Corolla</li>
                <li>Toyota Yaris</li>
            </ul>
            <p>These vehicles are ideal for various travel needs, from business trips to family vacations.</p>
        </div>
    </div>
</div>

{/* 
<ol style={{color:"black", fontWeight:"400"}}>
    <li><strong>What type of transmission do the standard cars have?</strong>
        <p>All of our standard sedan cars come equipped with automatic transmissions, offering a smooth and easy driving experience for both city commutes and longer trips.</p>
    </li>
    <li><strong>Can I book a standard car for out-of-city travel?</strong>
        <p>Yes, our standard cars are available for out-of-city travel. You can book them with a driver, making it convenient for long-distance journeys to other cities.</p>
    </li>
    <li><strong>Are standard cars available for short-term rentals?</strong>
        <p>We offer short-term rentals for our Toyota Corolla GLI model, with a minimum rental period of 5 hours, and these rentals include a driver.</p>
    </li>
    <li><strong>Are standard cars available with or without a driver?</strong>
        <p>Yes, our standard cars can be rented with or without a driver. However, please note that the self-drive option is only available for rentals within Karachi at this time.</p>
    </li>
    <li><strong>What types of cars are available in the standard rental category?</strong>
        <p>In our standard rental fleet, we offer a selection of popular sedan models, including:</p>
        <ul>
            <li>Honda Civic</li>
            <li>Honda City</li>
            <li>Toyota Corolla</li>
            <li>Toyota Yaris</li>
        </ul>
        <p>These vehicles are ideal for various travel needs, from business trips to family vacations.</p>
    </li>
</ol> */}

</section>


    </div>
            </div>
            </div>
         

                      
                    </div>{/* End .row */}
                  </div>{/* End .products */}
                  <nav aria-label="Page navigation">
                    <ul className="pagination justify-content-center">
                      <li className="page-item disabled">
                        <a className="page-link page-link-prev" href="#" aria-label="Previous" tabIndex={-1} aria-disabled="true">
                          <span aria-hidden="true"><i className="icon-long-arrow-left" /></span>Prev
                        </a>
                      </li>
                      <li className="page-item active" aria-current="page"><a className="page-link" href="#">1</a></li>
                      {/* <li className="page-item"><a className="page-link" href="#">2</a></li>
                      <li className="page-item"><a className="page-link" href="#">3</a></li>
                      <li className="page-item-total">of 6</li> */}
                      <li className="page-item">
                        <a className="page-link page-link-next" href="#" aria-label="Next">
                          Next <span aria-hidden="true"><i className="icon-long-arrow-right" /></span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>{/* End .col-lg-9 */}
                
              </div>{/* End .row */}
            </div>{/* End .container */}
          </div>{/* End .page-content */}
        </main>{/* End .main */}




</>
        )
    }



export default SubCategoriesProduct;