import React, {useState ,useRef , useEffect} from 'react';

import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom';

import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';


import Map from './abcd';



const containerStyle = {
  width: '100%',
  height: '400px'
};

// const center = {
//   lat: 24.806811, // آپ کی ابتدائی لوکیشن
//   lng: 67.066689
  
// };


const Checkout = (props) => {
  // const [location, setLocation] = useState(center);
  const [Map1, setMap1] = useState(false);
  const [address, setAddress] = useState('Enter your full address or closest possible address available on Google map');
  const [center, setcenter] = useState({
      lat: 24.806811, 
      lng: 67.066689   
    });
  const mapRef = useRef(null);

  // const onMapLoad = (map) => {
  //   mapRef.current = map;
  // };

  // const handleDragEnd = (event) => {
  //   console.log(event)
  //   if (event.latLng) {
  //   const newLocation = {
  //     lat: event.latLng.lat(),
  //     lng: event.latLng.lng()
  //   };
  //   setLocation(newLocation);
  //   // یہاں آپ کو ایڈریس حاصل کرنے کے لیے گوگل جیوکوڈنگ API کا استعمال کرنا ہوگا
  //   // یہ صرف ایک مثال ہے
  //   setAddress(`Lat: ${newLocation.lat}, Lng: ${newLocation.lng}`);
  //   }
  // };

  useEffect(() =>{

  if (navigator.geolocation) {
    navigator.geolocation.watchPosition(function (position) {
        console.log('latitude >>>>>>>>', position.coords.latitude);
        console.log('longitude >>>>>>>>', position.coords.longitude);
        setcenter ({
         lat: position.coords.latitude, 
         lng: position.coords.longitude
         
       })
    });
    
}else{
  setcenter ( {
   lat: 24.806811, 
   lng: 67.066689
   
 })
  
}


},[])


  const [markerPosition, setMarkerPosition] = useState(center);

  const onMapClick = (event) => {
      if (event.latLng) {
          const lat = event.latLng.lat();
          const lng = event.latLng.lng();
          setMarkerPosition({ lat, lng });
          console.log('Clicked location:', lat, lng);
      

          fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyCWzmaHoqleq6CHMgru65hqB0w5LxN_oF8`)
          .then(res => res.json())
          .then(address =>{
            
          setAddress(address.plus_code.compound_code
          )
          })
          





          

          // const url=`https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}`;
          // fetch(url)
          // .then(res =>res.json())
          // .then(data=>{
          //   console.log(data.address)
          //   let a 
          //   if(data.address.amenity){
          //    a = data.address.amenity+" "+data.address.road +" " +data.address.neighbourhood+" "+ data.address.city +" " +data.address.country +" "+ data.address.postcode
          //   }
          //   if(! data.address.amenity && data.address.house_number){
          //    a = "House No. "+" "+data.address.house_number+" "+data.address.road +" " +data.address.neighbourhood+" "+ data.address.city +" " +data.address.country +" "+ data.address.postcode
          //   }
          //   if(! data.address.amenity && ! data.address.house_number)
          //   a = data.address.road +" " +data.address.neighbourhood+" "+ data.address.city +" " +data.address.country +" "+ data.address.postcode
          //   setAddress(a)
          //   })



      }
      }
  const setChangemap = () => {
     
      }

     
  
        return (
            
            <center>

<div class="col-md-3">
									<div class="form-group">
										<span class="form-label">PICK-UP Address</span>
                    
										{/* <input type="text"  class="form-control"  required="required" aria-required="true"  value={pickup} onChange={(e)=>setpickup(e.target.value)}/> */}
										<button type="text"  class="form-control" style={{color:"black"}} onClick={()=>setMap1(!Map1)} >Fill Your Complete Address</button>

                    <span class="select-arrow"></span>

									</div>
								</div>

{Map1 ?

<div className="popup-container">
      <div className="popup-content">
        <div style={{ marginTop: '10px' }}>
        <input type="text" value={"Please select on the map or enter address."} readOnly style={{border:"none",width:"100%",padding:"0px 0px 10px 0px"}}/>
      </div>
        <div style={{ margin: '10px' }}>
        <input type="text" value={address} readOnly style={{width:"100%"}}/>
      </div>
  <LoadScript googleMapsApiKey="AIzaSyCWzmaHoqleq6CHMgru65hqB0w5LxN_oF8">
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={13}
                onClick={onMapClick}
            >
                <Marker position={markerPosition} />
            </GoogleMap>
        </LoadScript>
      <span className="close-icon" onClick={()=>setMap1(!Map1)}>&times;</span>
      <div style={{ marginTop:"20px"}}>
        <button type="text" value={"Submit"} readOnly style={{width:"100%",padding:"5px 0px",fontSize:"20px",borderRadius:"10px",textAlign:"center",background:"#95060D",color:"white"}} onClick={()=>setChangemap()}/>
      </div>
</div>
</div>

:
""

}



{/* 


<LoadScript googleMapsApiKey="AIzaSyCQ97GNePnHkOc0NE-Bd27v8lsbb76fL_I">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={location}
        zoom={10}
        onLoad={onMapLoad}
        onDragEnd={handleDragEnd}
      >
        <Marker
          position={location}
          draggable={true}
          onDragEnd={handleDragEnd}
        />
      </GoogleMap>
      <div style={{ marginTop: '10px' }}>
        <input type="text" value={address} readOnly />
      </div>
    </LoadScript> */}


















<div class="header123">
  <div class="header123-right">
    <Link to="/about">About Us</Link>

    <a class="dropdown123456">
    <Link class="dropbtn">Vehicles
    <KeyboardArrowDownIcon style={{color : "black" ,marginRight: "20px",marginTop: "-4px"}}/> 
    </Link>
    <div class="dropdown123456-content">
      <Link to="/luxury-cars">Luxury Cars</Link>
      <Link to="/standard-cars">Standard Cars</Link>
      <Link to="/suv-cars">SUV Cars</Link>
      <Link to="/budget-cars">Budget Cars</Link>
      <Link to="/coasters-cars">Coasters Cars</Link>
      <Link to="/one-way-cars">One-Way Cars</Link>
      <Link to="/monthly-cars">Monthly Rental Cars</Link>
    </div>
  </a>
    <a class="dropdown123456">
    <Link class="dropbtn">Pages
    <KeyboardArrowDownIcon style={{color : "black" ,marginRight: "20px",marginTop: "-4px"}}/> 
    </Link>
    <div class="dropdown123456-content">
      
<Link to="/rent-a-car-rates-in-karachi">Rent a Car Rates in Karachi</Link>
<Link to="/cheap-car-hire-in-karachi-pakistan">Cheap Car Hire in Karachi, Pakistan</Link>
<Link to="/rent-car-services-in-karachi">Rent Car - Services in Karachi</Link>

<Link to="/rent-a-car-in-Pakistan">Rent A Car in Pakistan</Link>
<Link to="/rent-a-car-in-Islamabad">Rent A Car in Islamabad</Link>


<Link to="/rent-a-car-in-Defence">Rent A Car in Defence</Link>
<Link to="/rent-a-car-in-Sindhi-Muslim-Society">Rent A Car in Sindhi Muslim Society</Link>
<Link to="/rent-a-car-in-Malir-Cantt">Rent A Car in Malir Cantt</Link>
<Link to="/rent-a-car-in-Korangi-Industrial-Area">Rent A Car in Korangi Industrial Area</Link>
<Link to="/rent-a-car-in-Bahria-Town-Karachi">Rent A Car in Bahria Town Karachi</Link>
<Link to="/rent-a-car-in-Clifton">Rent A Car in Clifton</Link>
<Link to="/rent-a-car-in-Port-Qasim">Rent A Car in Port Qasim</Link>
<Link to="/rent-a-car-in-Nizamabad">Rent A Car in Nizamabad</Link>
<Link to="/rent-a-car-in-Bufferzone">Rent A Car in Bufferzone</Link>
<Link to="/rent-a-car-in-Tariq-Road">Rent A Car in Tariq Road</Link>
<Link to="/rent-a-car-in-Baldia-Town">Rent A Car in Baldia Town</Link>
<Link to="/rent-a-car-in-North-Nizamabad">Rent A Car in North-Nizamabad</Link>
<Link to="/rent-a-car-in-Gulistan-e-Johar">Rent A Car in Gulistan-e-Johar</Link>
<Link to="/rent-a-car-in-Gulshan-e-Iqbal">Rent A Car in Gulshan-e-Iqbal</Link>
<Link to="/rent-a-car-in-Gulshan-e-Hadeed">Rent A Car in Gulshan-e-Hadeed</Link>
<Link to="/rent-a-car-in-Gulshan-e-Maymar">Rent A Car in Gulshan-e-Maymar</Link>
<Link to="/rent-a-car-in-karachi">Rent A Car in karachi</Link>
<Link to="/car-rental-in-karachi-pakistan">Car Rental in karachi Pakistan</Link>
<Link to="/rent-a-car-in-lahore-islamabad-karachi">Rent a Car in Lahore, Islamabad & Karachi</Link>
<Link to="/rent-a-car-in-Karachi-Pakistan">Rent A Car in Karachi Pakistan</Link>
<Link to="/providing-quality-car-rental-services">Providing Quality Car Rental Services</Link>
<Link to="/revo-car-rental-in-karachi">Revo - Car Rental in Karachi</Link>
<Link to="/best-car-rental-rates-karachi">Best Car Rental Rates Karachi</Link>
<Link to="/karachi-rent-a-car-home-car">Karachi Rent a Car: Home Car</Link>

<Link to="/luxury-and-prestige-car-rental-in-karachi">Luxury and Prestige Car Rental in Karachi</Link>
<Link to="/rent-a-car-karachi-luxury-car-rental-services">Rent A Car Karachi - Luxury Car Rental Services</Link>
<Link to="/premium-car-rental-karachi-top-deals-worldwide">Premium Car Rental Karachi & Top Deals Worldwide</Link>
<Link to="/searching-for-a-car-to-rent-with-no-deposit-near-you">Searching for a Car to Rent With No Deposit Near You</Link>
<Link to="/book-hassle-free-car-rentals">Book Hassle-Free Car Rentals</Link>
<Link to="/get-your-next-car-booking-with-pak-dream-at-500-pkr-off">Get Your Next Car Booking With Pak Dream at 500 PKR Off</Link>



                                            <Link to="/rent-a-land-cruiser-v8-in-islamabad-rawalpindi">Rent A Land Cruiser V8 In Islamabad & Rawalpindi</Link>
<Link to="/audi-a3-for-rent-in-islamabad-rawalpindi-best-rates">Audi A3 For Rent in Islamabad | Rawalpindi | Best Rates</Link>
<Link to="/rent-a-audi-a5-search-a-car-car-rental-services">Rent A Audi A5 | Search A Car | Car Rental Services</Link>
<Link to="/royal-rent-a-car-in-karachi-3000prk-per-day">Royal Rent a Car in Karachi 3000PKR Per Day</Link>
<Link to="/karachi-tours-rent-a-car-gulshan-e-iqbal">Karachi Tours - Rent a Car - Gulshan e Iqbal</Link>
<Link to="/rent-a-car-dha-rent-a-car-lahore-hire-luxury-car-rental">Rent a Car DHA | Rent a Car Lahore | Hire Luxury Car Rental</Link>
<Link to="/can-i-rent-a-car-for-just-a-few-hours">Can I Rent a Car for Just a Few Hours?</Link>
<Link to="/car-rental-rent-a-car-quickly-and-easy-in-karachi">Car Rental | Rent a Car Quickly and Easy in Karachi</Link>
<Link to="/hourly-car-rental-choose-the-rate-thats-right-for-you">Hourly Car Rental | Choose the Rate That's Right for You</Link>
<Link to="/book-hourly-car-rental-save-upto-200-off-in-karachi">Book Hourly Car Rental & Save Up to 200 Off in Karachi</Link>
<Link to="/are-you-looking-for-monthly-car-for-rent-in-islamabad">Are You Looking for Monthly Car For Rent in Islamabad?</Link>
<Link to="/rent-a-car-karachi-provides-an-expert-vehicle-maintenance-team">Rent a Car Karachi Provides an Expert Vehicle Maintenance Team</Link>
<Link to="/rent-a-car-rates-in-karachi-with-driver-online-car-rental">Rent a Car Rates in Karachi | With Driver Online Car Rental</Link>
<Link to="/karachi-super-rent-a-car-all-over-pakistan">Karachi Super Rent a Car | All Over Pakistan</Link>
<Link to="/bullet-proof-vehicles-at-car-rentals-karachi-pakistan">Bullet Proof Vehicles At Car Rentals karachi Pakistan</Link>

                                            
                                              <Link to="/hotel-in-karachi">hotel in karachi</Link>



                                              <Link to="/rent-a-car-in-Jhelum">Rent A Car in Jhelum</Link>
                                              <Link to="/rent-a-car-in-Lahore">Rent A Car in Lahore</Link>
                                              <Link to="/rent-a-car-in-Multan">Rent A Car in Multan</Link>
                                              <Link to="/rent-a-car-in-Faisalabad">Rent A Car in Faisalabad</Link>
                                              <Link to="/rent-a-car-in-Larkana">Rent A Car in Larkana</Link>
                                              <Link to="/rent-a-car-in-Hyderabad">Rent A Car in Hyderabad</Link>
                                              <Link to="/rent-a-car-in-Abbottabad">Rent A Car in Abbottabad</Link>
                                              <Link to="/rent-a-car-in-Gujranwala">Rent A Car in Gujranwala</Link>
                                              <Link to="/rent-a-car-in-Rahim-Yaar-Khan">Rent A Car in Rahim Yaar Khan</Link>
                                              <Link to="/rent-a-car-in-Peshawar">Rent A Car in Peshawar</Link>
                                              <Link to="/rent-a-car-in-Sukkur">Rent A Car in Sukkur</Link>
                                              <Link to="/rent-a-car-in-Dadu">Rent A Car in Dadu</Link>
                                              <Link to="/rent-a-car-in-Shahdadkot">Rent A Car in Shahdadkot</Link>



                                              <Link to="/rent-a-car-in-Sahiwal">Rent A Car in Sahiwal</Link>
                                              <Link to="/rent-a-car-in-Sialkot">Rent A Car in Sialkot</Link>
                                              <Link to="/rent-a-car-in-Gujrat">Rent A Car in Gujrat</Link>
                                              <Link to="/rent-a-car-in-Nawabshah">Rent A Car in Nawabshah</Link>
                                              <Link to="/categories/One%20Way%20Drop/all-products">One Way Drop</Link>
                                              <Link to="/one-way-drop-in-karachi-to-islamabad">One Way Drop karachi to Islamabad</Link>
                                              <Link to="/one-way-drop-in-karachi-to-faisalabad">One Way Drop karachi to faisalabad</Link>
                                              <Link to="/one-way-drop-in-karachi-to-multan">One Way Drop karachi to Multan</Link>
                                              <Link to="/one-way-drop-in-karachi-to-lahore">One Way Drop karachi to lahore</Link>
                                              <Link to="/rent-a-car-in-Airport-Pick-&-Drop">Rent A Car For Airport Pick & Drop</Link>
                                              <Link to="/rent-a-car-in-Wedding-Rent-A-Car">Rent A Car in Wedding Transport</Link>
                                              <Link to="/rent-a-car-in-Landhi-Industrial-Area">Rent A Car in Landhi Industrial Area</Link>
                                              <Link to="/tourism">Rent A Car For Tourism</Link>
                                              
                                              <Link to="/rent-a-car-karachi-car-rental-service-with-driver-pakistan">Rent a Car Karachi - Car Rental Service with Driver Pakistan</Link>


  <Link to="/rent-a-car-in-badshahi-mosque-lahore">Rent A Car for Badshahi Mosque</Link>


  <Link to="/rent-a-car-in-shalimar-bagh-lahore">Rent A Car for Shalimar Bagh</Link>


  <Link to="/rent-a-car-in-tomb-of-jahangir-lahore">Rent A Car for Tomb of Jahangir</Link>


  <Link to="/rent-a-car-in-minar-e-pakistan-lahore">Rent A Car for Minar-e-Pakistan</Link>


  <Link to="/rent-a-car-in-lahore-museum">Rent A Car for Lahore Museum</Link>


  <Link to="/rent-a-car-in-wazir-khan-mosque-lahore">Rent A Car for Wazir Khan Mosque</Link>


  <Link to="/rent-a-car-in-delhi-gate-lahore">Rent A Car for Delhi Gate</Link>


  <Link to="/rent-a-car-in-anarkali-bazar-lahore">Rent A Car for Anarkali Bazar</Link>


  <Link to="/rent-a-car-in-liberty-market-lahore">Rent A Car for Liberty Market</Link>


  <Link to="/rent-a-car-in-pearl-continental-hotel-lahore">Rent A Car for Pearl Continental Hotel</Link>

<Link to="/wedding-car-rental-in-karachi">Wedding - Car Rental in Karachi</Link>
<Link to="/rent-a-car-for-wedding-car-rental-services">Rent a Car For Wedding | Car Rental Services</Link>
<Link to="/wedding-rent-a-car-car-rental-pakistan">Wedding Rent A Car - Car Rental Pakistan</Link>
<Link to="/best-rent-a-car-karachi-book-car-for-any-travel-purpose">Best Rent A Car Karachi - Book Car For Any Travel Purpose</Link>
<Link to="/cheap-premium-car-rental-in-karachi">Cheap Premium Car Rental in Karachi</Link>



    </div>
  </a>

    <Link to="/Corporate-Equiries">Corporate Enquiries</Link>
    <Link to="/Blogs">Blogs</Link>
    {/* <Link to="/one-way-cars">One Way Drop</Link> */}
	    <a href="#clients677">Our Clients</a>
    <Link to="/contact">Contact Us </Link>


</div>
</div>





{/* 
<Link to="/rent-a-car-rates-in-karachi">Rent a Car Rates in Karachi</Link>
<Link to="/cheap-car-hire-in-karachi-pakistan">Cheap Car Hire in Karachi, Pakistan</Link>
<Link to="/rent-car-services-in-karachi">Rent Car - Services in Karachi</Link>

<Link to="/rent-a-car-in-Pakistan">Rent A Car in Pakistan</Link>
<Link to="/rent-a-car-in-Islamabad">Rent A Car in Islamabad</Link>


<Link to="/rent-a-car-in-Defence">Rent A Car in Defence</Link>
<Link to="/rent-a-car-in-Sindhi-Muslim-Society">Rent A Car in Sindhi Muslim Society</Link>
<Link to="/rent-a-car-in-Malir-Cantt">Rent A Car in Malir Cantt</Link>
<Link to="/rent-a-car-in-Korangi-Industrial-Area">Rent A Car in Korangi Industrial Area</Link>
<Link to="/rent-a-car-in-Bahria-Town-Karachi">Rent A Car in Bahria Town Karachi</Link>
<Link to="/rent-a-car-in-Clifton">Rent A Car in Clifton</Link>
<Link to="/rent-a-car-in-Port-Qasim">Rent A Car in Port Qasim</Link>
<Link to="/rent-a-car-in-Nizamabad">Rent A Car in Nizamabad</Link>
<Link to="/rent-a-car-in-Bufferzone">Rent A Car in Bufferzone</Link>
<Link to="/rent-a-car-in-Tariq-Road">Rent A Car in Tariq Road</Link>
<Link to="/rent-a-car-in-Baldia-Town">Rent A Car in Baldia Town</Link>
<Link to="/rent-a-car-in-North-Nizamabad">Rent A Car in North-Nizamabad</Link>
<Link to="/rent-a-car-in-Gulistan-e-Johar">Rent A Car in Gulistan-e-Johar</Link>
<Link to="/rent-a-car-in-Gulshan-e-Iqbal">Rent A Car in Gulshan-e-Iqbal</Link>
<Link to="/rent-a-car-in-Gulshan-e-Hadeed">Rent A Car in Gulshan-e-Hadeed</Link>
<Link to="/rent-a-car-in-Gulshan-e-Maymar">Rent A Car in Gulshan-e-Maymar</Link>
<Link to="/rent-a-car-in-karachi">Rent A Car in karachi</Link>
<Link to="/car-rental-in-karachi-pakistan">Car Rental in karachi Pakistan</Link>
<Link to="/rent-a-car-in-lahore-islamabad-karachi">Rent a Car in Lahore, Islamabad & Karachi</Link>
<Link to="/rent-a-car-in-Karachi-Pakistan">Rent A Car in Karachi Pakistan</Link>
<Link to="/providing-quality-car-rental-services">Providing Quality Car Rental Services</Link>
<Link to="/revo-car-rental-in-karachi">Revo - Car Rental in Karachi</Link>
<Link to="/best-car-rental-rates-karachi">Best Car Rental Rates Karachi</Link>
<Link to="/karachi-rent-a-car-home-car">Karachi Rent a Car: Home Car</Link>

<Link to="/luxury-and-prestige-car-rental-in-karachi">Luxury and Prestige Car Rental in Karachi</Link>
<Link to="/rent-a-car-karachi-luxury-car-rental-services">Rent A Car Karachi - Luxury Car Rental Services</Link>
<Link to="/premium-car-rental-karachi-top-deals-worldwide">Premium Car Rental Karachi & Top Deals Worldwide</Link>
<Link to="/searching-for-a-car-to-rent-with-no-deposit-near-you">Searching for a Car to Rent With No Deposit Near You</Link>
<Link to="/book-hassle-free-car-rentals">Book Hassle-Free Car Rentals</Link>
<Link to="/get-your-next-car-booking-with-pak-dream-at-500-pkr-off">Get Your Next Car Booking With Pak Dream at 500 PKR Off</Link>



                                            <Link to="/rent-a-land-cruiser-v8-in-islamabad-rawalpindi">Rent A Land Cruiser V8 In Islamabad & Rawalpindi</Link>
<Link to="/audi-a3-for-rent-in-islamabad-rawalpindi-best-rates">Audi A3 For Rent in Islamabad | Rawalpindi | Best Rates</Link>
<Link to="/rent-a-audi-a5-search-a-car-car-rental-services">Rent A Audi A5 | Search A Car | Car Rental Services</Link>
<Link to="/royal-rent-a-car-in-karachi-3000prk-per-day">Royal Rent a Car in Karachi 3000PKR Per Day</Link>
<Link to="/karachi-tours-rent-a-car-gulshan-e-iqbal">Karachi Tours - Rent a Car - Gulshan e Iqbal</Link>
<Link to="/rent-a-car-dha-rent-a-car-lahore-hire-luxury-car-rental">Rent a Car DHA | Rent a Car Lahore | Hire Luxury Car Rental</Link>
<Link to="/can-i-rent-a-car-for-just-a-few-hours">Can I Rent a Car for Just a Few Hours?</Link>
<Link to="/car-rental-rent-a-car-quickly-and-easy-in-karachi">Car Rental | Rent a Car Quickly and Easy in Karachi</Link>
<Link to="/hourly-car-rental-choose-the-rate-thats-right-for-you">Hourly Car Rental | Choose the Rate That's Right for You</Link>
<Link to="/book-hourly-car-rental-save-upto-200-off-in-karachi">Book Hourly Car Rental & Save Up to 200 Off in Karachi</Link>
<Link to="/are-you-looking-for-monthly-car-for-rent-in-islamabad">Are You Looking for Monthly Car For Rent in Islamabad?</Link>
<Link to="/rent-a-car-karachi-provides-an-expert-vehicle-maintenance-team">Rent a Car Karachi Provides an Expert Vehicle Maintenance Team</Link>
<Link to="/rent-a-car-rates-in-karachi-with-driver-online-car-rental">Rent a Car Rates in Karachi | With Driver Online Car Rental</Link>
<Link to="/karachi-super-rent-a-car-all-over-pakistan">Karachi Super Rent a Car | All Over Pakistan</Link>
<Link to="/bullet-proof-vehicles-at-car-rentals-karachi-pakistan">Bullet Proof Vehicles At Car Rentals karachi Pakistan</Link>

                                            
                                              <Link to="/hotel-in-karachi">hotel in karachi</Link>



                                              <Link to="/rent-a-car-in-Jhelum">Rent A Car in Jhelum</Link>
                                              <Link to="/rent-a-car-in-Lahore">Rent A Car in Lahore</Link>
                                              <Link to="/rent-a-car-in-Multan">Rent A Car in Multan</Link>
                                              <Link to="/rent-a-car-in-Faisalabad">Rent A Car in Faisalabad</Link>
                                              <Link to="/rent-a-car-in-Larkana">Rent A Car in Larkana</Link>
                                              <Link to="/rent-a-car-in-Hyderabad">Rent A Car in Hyderabad</Link>
                                              <Link to="/rent-a-car-in-Abbottabad">Rent A Car in Abbottabad</Link>
                                              <Link to="/rent-a-car-in-Gujranwala">Rent A Car in Gujranwala</Link>
                                              <Link to="/rent-a-car-in-Rahim-Yaar-Khan">Rent A Car in Rahim Yaar Khan</Link>
                                              <Link to="/rent-a-car-in-Peshawar">Rent A Car in Peshawar</Link>
                                              <Link to="/rent-a-car-in-Sukkur">Rent A Car in Sukkur</Link>
                                              <Link to="/rent-a-car-in-Dadu">Rent A Car in Dadu</Link>
                                              <Link to="/rent-a-car-in-Shahdadkot">Rent A Car in Shahdadkot</Link>



                                              <Link to="/rent-a-car-in-Sahiwal">Rent A Car in Sahiwal</Link>
                                              <Link to="/rent-a-car-in-Sialkot">Rent A Car in Sialkot</Link>
                                              <Link to="/rent-a-car-in-Gujrat">Rent A Car in Gujrat</Link>
                                              <Link to="/rent-a-car-in-Nawabshah">Rent A Car in Nawabshah</Link>
                                              <Link to="/categories/One%20Way%20Drop/all-products">One Way Drop</Link>
                                              <Link to="/one-way-drop-in-karachi-to-islamabad">One Way Drop karachi to Islamabad</Link>
                                              <Link to="/one-way-drop-in-karachi-to-faisalabad">One Way Drop karachi to faisalabad</Link>
                                              <Link to="/one-way-drop-in-karachi-to-multan">One Way Drop karachi to Multan</Link>
                                              <Link to="/one-way-drop-in-karachi-to-lahore">One Way Drop karachi to lahore</Link>
                                              <Link to="/rent-a-car-in-Airport-Pick-&-Drop">Rent A Car For Airport Pick & Drop</Link>
                                              <Link to="/rent-a-car-in-Wedding-Rent-A-Car">Rent A Car in Wedding Transport</Link>
                                              <Link to="/rent-a-car-in-Landhi-Industrial-Area">Rent A Car in Landhi Industrial Area</Link>
                                              <Link to="/tourism">Rent A Car For Tourism</Link>
                                              
                                              <Link to="/rent-a-car-karachi-car-rental-service-with-driver-pakistan">Rent a Car Karachi - Car Rental Service with Driver Pakistan</Link>


  <Link to="/rent-a-car-in-badshahi-mosque-lahore">Rent A Car for Badshahi Mosque</Link>


  <Link to="/rent-a-car-in-shalimar-bagh-lahore">Rent A Car for Shalimar Bagh</Link>


  <Link to="/rent-a-car-in-tomb-of-jahangir-lahore">Rent A Car for Tomb of Jahangir</Link>


  <Link to="/rent-a-car-in-minar-e-pakistan-lahore">Rent A Car for Minar-e-Pakistan</Link>


  <Link to="/rent-a-car-in-lahore-museum">Rent A Car for Lahore Museum</Link>


  <Link to="/rent-a-car-in-wazir-khan-mosque-lahore">Rent A Car for Wazir Khan Mosque</Link>


  <Link to="/rent-a-car-in-delhi-gate-lahore">Rent A Car for Delhi Gate</Link>


  <Link to="/rent-a-car-in-anarkali-bazar-lahore">Rent A Car for Anarkali Bazar</Link>


  <Link to="/rent-a-car-in-liberty-market-lahore">Rent A Car for Liberty Market</Link>


  <Link to="/rent-a-car-in-pearl-continental-hotel-lahore">Rent A Car for Pearl Continental Hotel</Link>

<Link to="/wedding-car-rental-in-karachi">Wedding - Car Rental in Karachi</Link>
<Link to="/rent-a-car-for-wedding-car-rental-services">Rent a Car For Wedding | Car Rental Services</Link>
<Link to="/wedding-rent-a-car-car-rental-pakistan">Wedding Rent A Car - Car Rental Pakistan</Link>
<Link to="/best-rent-a-car-karachi-book-car-for-any-travel-purpose">Best Rent A Car Karachi - Book Car For Any Travel Purpose</Link>
<Link to="/cheap-premium-car-rental-in-karachi">Cheap Premium Car Rental in Karachi</Link> */}











<section id="clients" class="section-bg">

      <div class="container">

        <div class="section-header">
          <h3></h3>
          <p></p>
          <h3 className="title text-center font-weight-bold mt-3">Our CLients <br />
                <span style={{color : "#95060D"}}>
               Meet our happy clients
                  </span> 
</h3>
       
        </div>

        <div class="row no-gutters clients-wrap clearfix wow fadeInUp" style={{visibility: "visible", animationName: "fadeInUp"}}>

          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo">
              <img src="https://res.cloudinary.com/dxfq3iotg/image/upload/v1559460149/abof.png" class="img-fluid" alt="" />
            </div>
          </div>
          
          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo">
              <img src="https://res.cloudinary.com/dxfq3iotg/image/upload/v1559460224/cropped-cropped-20170720-lucuLogo-squ2-e1500543540803.png" class="img-fluid" alt="" />
            </div>
          </div>
        
          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo">
              <img src="https://res.cloudinary.com/dxfq3iotg/image/upload/v1559460269/104840a62d46c05d285762857fecb61a.png" class="img-fluid" alt="" />
            </div>
          </div>
          
          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo">
              <img src="https://res.cloudinary.com/dxfq3iotg/image/upload/v1559460358/client-4.png" class="img-fluid" alt="" />
            </div>
          </div>
          
          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo">
              <img src="https://res.cloudinary.com/dxfq3iotg/image/upload/v1559460379/client-5.png" class="img-fluid" alt="" />
            </div>
          </div>
        
          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo">
              <img src="https://res.cloudinary.com/dxfq3iotg/image/upload/v1559460398/client-6.png" class="img-fluid" alt="" />
            </div>
          </div>
          
          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo">
              <img src="https://res.cloudinary.com/dxfq3iotg/image/upload/v1559460418/client-7.png" class="img-fluid" alt="" />
            </div>
          </div>
          
          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo">
              <img src="https://bootstrapmade.com/demo/themes/NewBiz/img/clients/client-8.png" class="img-fluid" alt="" />
            </div>
          </div>

        </div>

      </div>

    </section>














<section>














  <div class="container">
    <div class="row">




















      <h1>CSS3 Fade in animation - On Page Load</h1>
      
      <div class="col-sm-6">
        <div class="box animate fadeInUp one">
          Fade in Up
        </div>
      </div>

      <div class="col-sm-6">
        <div class="box animate fadeInDown two">
          Fade in Down
        </div>
      </div>

      <div class="col-sm-6">
        <div class="box animate fadeInLeft three">
          Fade in Left
        </div>
      </div>

      <div class="col-sm-6">
        <div class="box animate fadeInRight four">
          Fade in Right
        </div>
      </div>

      <div class="col-sm-6">
        <div class="box animate fadeIn five">
          Fade in
        </div>
      </div>

      <div class="col-sm-6">
        <div class="box animate fadeInUpBig six">
          Fade in Up Big
        </div>
      </div>

      <div class="col-sm-6">
        <div class="box animate fadeInDownBig seven">
          Fade in Down Big
        </div>
      </div>

      <div class="col-sm-6">
        <div class="box animate fadeInRightBig eight">
          Fade in Right Big
        </div>
      </div>
      <div class="col-sm-6">
        <div class="box animate fadeInLeftBig eight">
          Fade in Right Big
        </div>
      </div>

    </div>
  </div>
</section>
<div class="animate fadeInLeftBig eight">fadeInLeft</div>


                <div class="line"></div>
                <a href="#" class="animated-button1">
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  Book Now
</a>
                {/* <button class="glow-on-hover" type="button">HOVER ME, THEN CLICK ME!</button> */}
            </center>
        )
    
}

export default Checkout;














// import React, {useState ,useRef , useEffect} from 'react';
// import { GoogleMap, useJsApiLoader,Marker } from '@react-google-maps/api'

// const containerStyle = {
//   width: '100%',
//   height: '400px',
// }

// const center = {
//   lat: 24.8607, 
//   lng: 67.0011
// }

// function MyComponent() {
//   const { isLoaded } = useJsApiLoader({
//     id: 'google-map-script',
//     googleMapsApiKey: 'AIzaSyCQ97GNePnHkOc0NE-Bd27v8lsbb76fL_I',
//   })

//   const [map, setMap] = React.useState(null)
//   const [address, setAddress] = useState('');

//   const onLoad = React.useCallback(function callback(map) {
//     // This is just an example of getting and using the map instance!!! don't just blindly copy!
//     const bounds = new window.google.maps.LatLngBounds(center)
//     map.fitBounds(bounds)

//     setMap(map)
//   }, [])

//   const onUnmount = React.useCallback(function callback(map) {
//     setMap(null)
//   }, [])

//     const handleDragEnd = (event) => {
//     console.log(event)
//     if (event.latLng) {
//     const newLocation = {
//       lat: event.latLng.lat(),
//       lng: event.latLng.lng()
//     };
//     setLocation(newLocation);
//     // یہاں آپ کو ایڈریس حاصل کرنے کے لیے گوگل جیوکوڈنگ API کا استعمال کرنا ہوگا
//     // یہ صرف ایک مثال ہے
//     setAddress(`Lat: ${newLocation.lat}, Lng: ${newLocation.lng}`);
//     console.log(setAddress(`Lat: ${newLocation.lat}, Lng: ${newLocation.lng}`))
//     }
//   };

//   return isLoaded ? (
//     <GoogleMap
//       mapContainerStyle={containerStyle}
//       center={center}
//       zoom={13}
//       onLoad={onLoad}
//       onUnmount={onUnmount}
//               onDragEnd={handleDragEnd}

//     >
//          <Marker
//           position={center}
//           draggable={true}
//           onDragEnd={handleDragEnd}
//         />
//       {/* Child components, such as markers, info windows, etc. */}
//       <></>
//     </GoogleMap>
//   ) : (
//     <></>
//   )
// }

// export default React.memo(MyComponent)