import React, { useState , useEffect } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import ch6 from '../asset1/images/contact-header-bg.jpg';
// import ch7 from '../asset1/images/stores/img-1.jpg';
// import ch8 from '../asset1/images/stores/img-2.jpg';
import ch34 from '../assets/images/bakc.jpg';

import ch1 from '../assets/images/Pak-Dream-1.jpg';
import ch2 from '../assets/images/Pak-Dream-2.jpg';


import {Helmet} from "react-helmet";



const Contact = (props)=>  {
    const [name, setname] = useState("") 
    const [lname, setlname] = useState("") 
    const [email, setemail] = useState("") 
    const [subject, setsubject] = useState("") 
    const [Message, setMessage] = useState("") 
    const [AllData , setAllData] = useState([])
    useEffect(() =>{
        window.scrollTo(0, 0)

    fetch("https://pak-dream-admin-backs.vercel.app/AllTCreatBLog",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res3=>res3.json())
        .then(res4=>{
            let dat = []
            let dat1 = []
            res4.map((res55,c)=>{
                dat.push(res55)
                dat1.push(0)

     
            })
                        console.log(dat)
            let data = dat.sort((a, b) => {
                return a.sort - b.sort;
            });
            setAllData(data)

        })
      },[])

    const Submitdata = (e)=>{
        e.preventDefault()

        fetch("https://d-luxe-housebackend.vercel.app/touchIn",{
            method: 'POST' , 
            headers :  {
              "Content-Type" : "application/json" , 
            } , 
            body : JSON.stringify({
              name  ,
              lname  ,
              email  ,
              subject  ,
              Message  ,
            })
          })
          .then((res)=>res.json())
          .then((res2)  =>{
              console.log(res2)
              if (res2){
                swal("SucessFully Send "  )
                setname ("") 
                setlname("") 
                    setemail("") 
                    setsubject("") 
                    setMessage("") 
                // localStorage.setItem("User" , JSON.stringify("Yes") )
                    // props.history.push("/login")
              }
              else if (res2.Error){
                swal(res2.Error )
              }
              else{
                swal("Plz Try Again !"  )
              }
            // console.log(res2)
          })
          .catch((err)=>console.log(err))
        
    }



        return (
            <> 

            <div>
                 <Helmet>
                <link rel="canonical" href="https://pakdreamrentacar.com/tourism" />
                <meta name="description" content="Rent a car in Karachi and experience comfort and style with Pak Dream Rent a Car. Choose from our collection of tourism cars for rent and enjoy the most comfortable driving experience" />

            </Helmet>
          <main className="main">
        <nav aria-label="breadcrumb" className="breadcrumb-nav border-0 mb-0">
          <div className="container">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="">Home</a></li>
              {/* <li className="breadcrumb-item"><a href="#">Pages</a></li> */}
              <li className="breadcrumb-item active" aria-current="page">Tourism</li>
            </ol>
          </div>{/* End .container */}
        </nav>{/* End .breadcrumb-nav */}
        <div className="container">
          <div className="page-header page-header-big text-center" style={{backgroundImage:  'url('+ch34+')'}}>
            <h1 className="page-title">Tourism<span className="">keep in touch with us</span></h1>
          </div>{/* End .page-header */}
        </div>{/* End .container */}


        <div className='container' style={{margin : "30px 10px"}}>
            <div className='row'  >
            <div className="col-10 col-lg-10  col-sm-12" style={{margin  :"auto"}}>
            <h1 className="page-title">Travel and Tourism Services with Pak Dream Rent A Car</h1>
        
            <h2>Explore Pakistan in Style and Comfort with Pak Dream Rent A Car</h2>
<p style={{color:"black", fontWeight:"400"}}>
    Your ultimate partner for luxury travel, unforgettable tours, and seamless transportation services. Whether you're a solo traveler, a couple, a family, or a large group, we specialize in creating customized travel experiences that cater to your specific needs, preferences, and budget. From the bustling cities to the serene valleys, let us take you on a journey of discovery across Pakistan’s most beautiful and diverse destinations.
</p>

<h4>Why Choose Pak Dream Rent A Car for Travel and Tourism in Pakistan?</h4>
<p style={{color:"black", fontWeight:"400"}}>
    At Pak Dream Rent A Car, we don’t just offer a vehicle; we provide luxury, convenience, and peace of mind for every step of your journey. We go the extra mile to ensure your comfort, safety, and satisfaction, offering a personalized experience that you can enjoy from the moment you step into one of our premium vehicles. Here’s why we stand out:
</p>
<ul style={{color:"black", fontWeight:"400"}}>
    <li><strong>Tailored Travel Packages:</strong> Whether you want a scenic road trip, a historical city tour, a corporate retreat, or an adventurous hike in the mountains, we design customized itineraries that perfectly match your interests and goals.</li>
    <li><strong>Luxurious Fleet of Vehicles:</strong> Travel in absolute comfort and style with our diverse fleet of luxury cars, SUVs, vans, and coasters. Our vehicles are fully equipped with modern amenities to ensure a smooth and relaxing ride, no matter where you’re headed.</li>
    <li><strong>Professional Chauffeurs:</strong> Our experienced, courteous, and licensed chauffeurs are more than just drivers—they are travel guides who know the ins and outs of Pakistan’s top tourist spots. With local knowledge, they can offer valuable insights and make recommendations to enhance your experience.</li>
    <li><strong>Safety First:</strong> We prioritize your safety above all. Our vehicles are meticulously maintained, regularly inspected, and fully insured. Additionally, our chauffeurs are trained to ensure smooth, safe journeys, especially on long trips and challenging terrains.</li>
    <li><strong>End-to-End Services:</strong> We offer comprehensive services that cover every aspect of your trip, from airport transfers to luxury accommodations and guided tours of major attractions. You won’t have to worry about a thing—just enjoy the experience.</li>
</ul>

<h4>Popular Destinations We Cover in Pakistan</h4>
<p style={{color:"black", fontWeight:"400"}}>
    Pakistan is a country of stunning contrasts, from the mighty peaks of the Himalayas to the historic cities and serene valleys. Whether you're an adventure seeker, a culture enthusiast, or simply looking for a relaxing getaway, we can take you to some of the most iconic destinations in the country:
</p>

<h5>Hunza Valley</h5>
<p style={{color:"black", fontWeight:"400"}}>
    <strong>Why Visit:</strong> Known for its majestic mountain views, lush green valleys, and vibrant local culture, Hunza is the perfect place for nature lovers and adventure enthusiasts alike.<br/>
    <strong>Our Services:</strong> Explore Hunza with ease in our luxury SUVs or vans that comfortably accommodate your group and luggage. We offer guided tours to attractions like Baltit Fort, Altit Fort, and Eagle’s Nest.<br/>
    <strong>Duration:</strong> 4-5 days
</p>

<h5>Skardu</h5>
<p style={{color:"black", fontWeight:"400"}}>
    <strong>Why Visit:</strong> Skardu is a paradise for trekkers, with awe-inspiring landscapes, Shangrila Resort, and the breathtaking Deosai National Park. It's also a gateway for mountaineers seeking to summit K2.<br/>
    <strong>Our Services:</strong> Take a scenic drive to Skardu in our well-maintained 4x4 SUVs or vans. We offer expert guides for your journey and customized itineraries for short and long trips.<br/>
    <strong>Duration:</strong> 5-7 days
</p>

<h5>Islamabad & Murree</h5>
<p style={{color:"black", fontWeight:"400"}}>
    <strong>Why Visit:</strong> Islamabad, the capital city, offers a perfect blend of modernity and nature. Murree, a hill station just outside the city, is perfect for those looking to escape the summer heat and enjoy the cool, pine-scented air.<br/>
    <strong>Our Services:</strong> For short trips, luxury sedans or SUVs are ideal. We also offer group tours in vans for larger parties. Visit attractions like Daman-e-Koh, and Nathia Gali. <br/>
</p>


<h5>Lahore</h5>
<p style={{color:"black", fontWeight:"400"}}>
    <strong>Why Visit:</strong> The cultural heart of Pakistan, Lahore is home to historical landmarks, delicious food, and vibrant bazaars. Explore Badshahi Mosque, Lahore Fort, Shalimar Gardens, and experience the lively atmosphere of the Walled City.<br/>
    <strong>Our Services:</strong> We offer a variety of vehicles for city tours. Whether you're traveling solo or with a group, we have a luxury car or van to suit your needs.<br/>
    <strong>Duration:</strong> 3-4 days
</p>

<h5>Karachi</h5>
<p style={{color:"black", fontWeight:"400"}}>
    <strong>Why Visit:</strong> Karachi, Pakistan's largest city, offers everything from beautiful beaches like Hawksbay to a lively food scene and historical landmarks like the Quaid-e-Azam's Mausoleum.<br/>
    <strong>Our Services:</strong> Our luxury sedans or SUVs are perfect for exploring the city, while larger groups can opt for our vans and coasters. Enjoy a guided city tour for a deeper cultural experience.<br/>
    <strong>Duration:</strong> 2-4 days
</p>

<h5>Fairy Meadows & Naran Kaghan</h5>
<p style={{color:"black", fontWeight:"400"}}>
    <strong>Why Visit:</strong> These remote, breathtaking destinations offer some of the best trekking opportunities and views of Nanga Parbat. Enjoy camping, hiking, and unspoiled nature.<br/>
    <strong>Our Services:</strong> 4x4 SUVs and vans are ideal for the rugged terrain. We offer camping packages and guided tours to help you experience the untouched beauty of these regions.<br/>
    <strong>Duration:</strong> 5-7 days
</p>

<h4>Our Travel and Tourism Services</h4>
<p style={{color:"black", fontWeight:"400"}}>
    At Pak Dream Rent A Car, we offer a full range of travel services to ensure a smooth and luxurious experience:
</p>
<ul style={{color:"black", fontWeight:"400"}}>
    <li><strong>Airport Transfers:</strong> Avoid the hassle of taxis and enjoy a premium airport transfer to your hotel or destination. Our chauffeur will be waiting to greet you at the airport with a signboard and take you directly to your accommodation in comfort and style.</li>
    <li><strong>Guided Tours:</strong> Our experienced local guides are available for sightseeing tours in major cities and remote areas. They provide deep insights into the cultural, historical, and natural significance of each location.</li>
    <li><strong>Luxury Accommodation:</strong> We can arrange stays at five-star hotels, luxury resorts, or boutique guesthouses, depending on your preferences. We’ll ensure your accommodation is as comfortable and relaxing as your journey.</li>
    <li><strong>Adventure Tours:</strong> For the thrill-seekers, we organize adventure packages to the Karakoram Highway, Fairy Meadows, Skardu, and beyond. From trekking to mountain climbing and desert safaris, we make sure you have an unforgettable experience.</li>
    <li><strong>Corporate and Group Travel:</strong> For businesses or large groups, we offer corporate retreats, team-building trips, and group tours to top destinations. Our vans and coasters can comfortably accommodate large groups, and we provide personalized itineraries to meet your corporate or group travel needs.</li>
</ul>

<h4>Why Book Your Tour with Pak Dream Rent A Car?</h4>
<ul style={{color:"black", fontWeight:"400"}}>
    <li><strong>Flexibility:</strong> We understand that plans change, which is why we offer flexible travel options and custom itineraries. Change your travel dates, duration, or destinations without hassle.</li>
    <li><strong>No Hidden Fees:</strong> Our pricing is transparent, with no hidden costs. What you see is what you pay—just the cost of your vehicle, driver, fuel, and any optional services like accommodation and tour guides.</li>
    <li><strong>24/7 Customer Support:</strong> Our team is always available to assist with booking, last-minute changes, or emergencies. No matter the time or place, we’re here to help.</li>
</ul>

<h2>Book Your Dream Tour Today!</h2>
<p style={{color:"black", fontWeight:"400"}}>
Ready to explore Pakistan like never before? Pak Dream Rent A Car is your go-to provider for luxurious, personalized travel services across Pakistan. Whether it's a family vacation, honeymoon, business trip, or group outing, we are here to create the perfect travel experience for you

</p>

</div>
            </div>
            </div>


<div className='container'>
             <h2 class="title mb-4 text-center">We Provide Best Services For You
</h2>
                    <div class="row justify-content-center">
                        <div class="col-lg-4 col-sm-6">
                            <div class="icon-box text-center" style={{padding  :"30px 0px" ,color : "black", border:  "1px solid black"}}>
                                <span class="icon-box-icon">
                                    <i class="icon-info-circle"></i>
                                </span>
                                <div class="icon-box-content">
                                    <h3 class="icon-box-title">Tours and Trips
</h3>
                                    <p>We offer our services to the Tourists and Group Tours of Offices or Schools.

</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="icon-box text-center" style={{padding  :"30px 0px" ,color : "black", border:  "1px solid red", background: "#f23e3e"}}>
                                <span class="icon-box-icon">
                                    <i class="icon-star-o" style={{color :  "white"}}></i>
                                </span>
                                <div class="icon-box-content">
                                    <h3 class="icon-box-title" style={{color :  "white"}}>Weddings
</h3>
                                    <p style={{color :  "white"}}>We provide our car rental service on weddings for Groom and other attendees.
</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="icon-box text-center" style={{padding  :"30px 0px" ,color : "black", border:  "1px solid black"}}>
                                <span class="icon-box-icon">
                                    <i class="icon-heart-o"></i>
                                </span>
                                <div class="icon-box-content">
                                    <h3 class="icon-box-title">Families / Individuals
</h3>
                                    <p>We offer vehicles like cars and vans to the individuals as well as to the families.

</p>
                                </div>
                            </div>
                        </div>
                    </div>








                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-sm-6">
                           <img src={ch1} alt="" />
                        </div>

                        <div class="col-lg-6 col-sm-6">
                           <img src={ch2} alt="" />
                            
                        </div>

                        
                    </div>

<br />
<br />
             <h2 class="title mb-4 text-center">EXPLORE YOUR DESIRED ADVENTURES WITH PAK DREAM TOUR AND TOURISM

</h2>
                    <div class="row justify-content-center">
                        <div class="col-lg-4 col-sm-6">
                            <div class="icon-box text-center" style={{padding  :"30px 10px" ,color : "black", border:  "1px solid black"}}>
                                <span class="icon-box-icon">
                                    {/* <i class="icon-info-circle"></i> */}
                                </span>
                                <div class="icon-box-content">
                                    <h3 class="icon-box-title"> Naran Kaghan ​

</h3>
                                    <p>Naran Kaghan is a beautiful valley located in the Northern Areas of Pakistan. It is a very popular tourist destination and attracts a lot of visitors from all over the world. It is filled with lush green meadows, snow-capped mountains, crystal clear lakes and streams, and breathtaking views of the surrounding landscape. A trip to Naran Kaghan is sure to be an unforgettable experience, as it offers a great variety of activities to do.

</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="icon-box text-center" style={{padding  :"30px 10px" ,color : "black", border:  "1px solid black"}}>
                                <span class="icon-box-icon">
                                    {/* <i class="icon-star-o"></i> */}
                                </span>
                                <div class="icon-box-content">
                                    <h3 class="icon-box-title">Gilgit Baltistan
</h3>
                                    <p>Gilgit Baltistan is an amazing destination for a trip. With its mountains, glaciers, and rivers, it provides a unique and unforgettable experience. You can find a variety of activities, such as trekking, rock climbing, camping, and fishing. The scenery of the valleys and peaks is simply breathtaking. You can also explore the culture and tradition of the local people, which is very much alive and vibrant. The hospitality of the locals is also very warm.
</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="icon-box text-center" style={{padding  :"30px 10px" ,color : "black", border:  "1px solid black"}}>
                                <span class="icon-box-icon">
                                    {/* <i class="icon-heart-o"></i> */}
                                </span>
                                <div class="icon-box-content">
                                    <h3 class="icon-box-title">Hunza Valley
</h3>
                                    <p>Hunza Valley is a beautiful place to visit in Pakistan. It is located in the north of the country, in the Gilgit-Baltistan region. The valley is famous for its scenic beauty, with snow-capped mountains, lush green meadows and a picturesque river. The Hunza Valley is home to a number of attractions, including ancient forts, ancient monuments, and ancient architecture.

</p>
                                </div>
                            </div>
                        </div>
                    </div>





                    {AllData.map((res,i)=>{
        return(
            <div class="col-12 col-lg-4 col-sm-12 card1"  style={{cursor:"pointer"}}>
            {/* <h5>Title description, Dec 7, 2017</h5> */}
            <div class="fakeimg">
                <img src={res.Image} alt="" style={{height:"300px",cursor:"pointer"}}/>
            </div>
            <h1 style={{fontSize:"24px",padding:"10px 50px 10px 20px",width:"90%",cursor:"pointer"}}>{res.main} ... </h1>
            {/* <p>Some text..</p> */}
                    <p style={{overflow:"hidden",maxHeight:"100px",maxWidth:"90%",cursor:"pointer"}}> {res.text &&res.text ?<div dangerouslySetInnerHTML={{__html: res.text}} />:""}</p> 
                  <br />
                <center>
                        <span className="shadow-none" style={{borderRadius:"15px",maxWidth:"150px",color:"white",background :"rgb(87 1 6)",padding :"10px 15px",color  :"white",fontSize:"12px",fontWeight:"bold"}} onClick={()=>props.history.push(`/blog/${res.main.toLowerCase().trim().split(/[\s,\t,\n]+/).join('-')}`)}>Read More</span>
                    </center>

            </div>

        )
    })

    }













                    <div class="col-6 col-lg-6 col-xl-3" style={{margin : "auto"}}>
		                	<div class="btn-wrap">
		                		{/* <span>Round Style</span> */}
		                		<a href="tel:+923332221927" class="btn btn-round" style={{background : "#f23e3e"}}><i class="icon-long-arrow-right"></i><span>Contact Us</span></a>
		                	</div>
		                </div>



</div>

       







      </main>{/* End .main */}





              
            </div>
            </>
           
        )
    
}


export default Contact;