import React , {useState , useEffect} from 'react';
import { Link  ,useHistory } from 'react-router-dom';
import swal from 'sweetalert';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import logo1 from '../assets/images/logo-1.png';
// import logo1 from '../assets/images/apple-touch-icon.png';
// import Head1 from "../assets/images/header-bg-rpt.jpg"
// import SearchIcon from '@material-ui/icons/Search';
// import ClearIcon from '@material-ui/icons/Clear';
// import MenuIcon from '@material-ui/icons/Menu';
// import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
// import LocationOnIcon from '@material-ui/icons/LocationOn';
// import PhoneForwardedIcon from '@material-ui/icons/PhoneForwarded';
// import AddAlertIcon from '@material-ui/icons/AddAlert';
// // import AddAlertIcon from '@mui/icons-material/AddAlert';
// import SendIcon from '@material-ui/icons/Send';
// import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
// import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
// import FacebookIcon from '@material-ui/icons/Facebook';
// import InstagramIcon from '@material-ui/icons/Instagram';
// import TwitterIcon from '@material-ui/icons/Twitter';
// import YouTubeIcon from '@material-ui/icons/YouTube';
// import LinkedInIcon from '@material-ui/icons/LinkedIn';
// import FavoriteIcon from '@material-ui/icons/Favorite';
import { useSelector, useDispatch } from 'react-redux';

import Loader from "react-loader-spinner";



const Header =(props)=> {

  const [total1 , settotal1] = useState(0)
    const [search , setSearch] = useState("")
    const [DataPart2 , setDataPart2] = useState([])
    // const [UserDatat , setUserDatat] = useState({})
    // const [userHeader , setuserHeader] = useState(false)
    // const [menu1 , setmenu1] = useState(false)
    // const [adminHeader , setadminHeader] = useState(false)
    // const [displayMenu , setdisplayMenu] = useState(false)
    // const [MainLine , setMainLine] = useState("")
    // const [MainForum , setMainForum] = useState(false)
    const [MainCatogories , setMainCategories] = useState([])
    const [SubCategories , setSubCategories] = useState([])
    const [SubClassCategories , setSubClassCategories] = useState([])
    const [fvalue , setfvalue] = useState([])
    // const [fvalue1 , setfvalue1] = useState([])
    // const [fg , setfg] = useState(true)
    // const [abc , setabc] = useState(false)
    const [DisplaySearch , setDisplaySearch] = useState(false)
    // const [DisplayMenu , setDisplayMenu] = useState(false)
    // const [bht , setbht] = useState("")
    const [mainUser , setmainUser] = useState({})
    // const [couponShow1 , setcouponShow1] = useState(false)
    // const [name, setname] = useState("") 
    // const [email, setemail] = useState("") 
    // const [message, setmessage] = useState("") 
    // const [photo, setphoto] = useState("") 
    // const [allmessage , setallmessage] = useState([])
    const [cart1 , setcart1] = useState([])
    const [fav1 , setfav1] = useState([])
    // const [photo, setphoto] = useState("") 

    let history = useHistory();


    useEffect(() =>{
      document.getElementById("menu-mobile-1").style.transform = "translateX(280px)"
      document.getElementById("menu-mobile-1").style.visibility = "hidden"
      document.getElementById("menu-mobile-2").style.visibility = "hidden"
      var data1 =  JSON.parse(localStorage.getItem("Cart")) ?  JSON.parse(localStorage.getItem("Cart"))  : []
      var data2 =  JSON.parse(localStorage.getItem("Fav")) ?  JSON.parse(localStorage.getItem("Fav")) : []
      setcart1(data1)
      setfav1(data2)
      // let data5 =  JSON.parse(localStorage.getItem("CartPrice")) 
      // settotal1(data5)
      let j = 0 
      data1.map((item,i)=>{
        j = item.Total_Product_Price + j
     })
     settotal1(j)
        if( JSON.parse(localStorage.getItem("user main")) ) setmainUser(JSON.parse(localStorage.getItem("user main")))
        
     
        fetch("https://pak-dream-back.vercel.app/MainCatogories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res3=>res3.json())
        .then(res4=>{
            let dat = []
            let dat1 = []
            res4.map((res55,c)=>{
                dat.push(res55)
                dat1.push(0)
            })
                        console.log(dat)
            let data = dat.sort((a, b) => {
                return a.sort - b.sort;
            });
            setMainCategories(data)
            console.log(data)
            setfvalue(dat1)
        })
        if(! JSON.parse(localStorage.getItem("All1")) ){
        
        fetch("https://pak-dream-back.vercel.app/AllProduct",{
          method: "GET",
           headers :  {
           "Content-Type" : "application/json" , 
       }
      })
      .then(res5=>res5.json())
      .then(res6=>{
       localStorage.setItem("All1",JSON.stringify(res6))
      })
    }
        const inter = setInterval(()=>{
     
            var data1 =  JSON.parse(localStorage.getItem("Cart")) 
         if (data1) {
             setDataPart2(data1)
             setcart1(data1)
            //  const data = DataPart2.length  
            //  setDataPart2(data)
            }
         else setDataPart2(0)
            var data6 =  JSON.parse(localStorage.getItem("Fav")) 
         if (data6) {
             setfav1(data6)
            //  const data = DataPart2.length  
            //  setfav1(data)
            }
         else setfav1(0)

         if( JSON.parse(localStorage.getItem("user main")) ) setmainUser(JSON.parse(localStorage.getItem("user main")))
        
       

        },3000)
        
        return () => clearInterval(inter);
    },[])
  

    const changeFruit = (goto1) => {
      // setCurrentFruit(newFruit)
      // history.push(goto);
      window.location = goto1;
    }
    

    const SubmitData45342 = (e) =>{
      e.preventDefault()
      console.log("df")
      setDisplaySearch(true)
      localStorage.removeItem("SearchData")
          fetch("https://pak-dream-back.vercel.app/SearchProduct",{
                                  method: "POST",
                                  headers :  {
                                      "Content-Type" : "application/json" , 
                                  } ,
                                  body : JSON.stringify({
                                      search : search.toLowerCase() ,
      
                                  })
                              })
                              .then(res=>res.json())
                              .then((res1)=>{ 
                                
                                console.log(res1) 
                                  const SearchProductItem = []
                                  
                                  let search1 = search.split(" ")
                                res1.map((res2,i)=>{
                                    // console.log(res2.Product_Name.toLowerCase().split(" "),res2.Product_Title.toLowerCase().split(" "),i)
                                    var Name = res2.Product_Name.toLowerCase().split(" ")
                                     var Title = res2.Product_Title.toLowerCase().split(" ")
                                    // console.log(Name , Title)
                                    for (let j = 0; j < search1.length; j++) {
                                      
                                      Name.map((res3,i)=>{
  
                                          if (res3 === search1[j].toLowerCase().trim()){
                                              SearchProductItem.unshift(res2)
                                          }
                                      })
                                      Title.map((res4,i)=>{
                                        if (res4 === search1[j].toLowerCase().trim()){
                                          SearchProductItem.unshift(res2)
                                        }
                                      })
                                    }
                                })
                                  console.log(SearchProductItem)    

                                  console.log(SearchProductItem,props)
                                  let uniq = [...new Set(SearchProductItem)];
      
                                  localStorage.setItem("SearchData" , JSON.stringify(uniq) )
                                  
                                  setDisplaySearch(false)
                                  setTimeout(()=>{
                                      history.push("/search/product-item");
      
                                      // return <Redirect push to="/shop/search/product-item" /> 
                                      // props.history.push(`/shop/search/product-item`)
                                  },1000)   
                                  // localStorage.setItem("SearchData" , JSON.stringify(res1) )
                                  // props.history.push(`/shop/search/product-item`)
      
                                  
                          })
                          .catch(err=>{
                            console.log(err)
                            swal("There is An Error")                  
                            setDisplaySearch(false)
                              }) 
      }

const ChangeCateUrl = (res) =>{
  console.log("sfsfd")
  history.push("/categories/"+res+"/all-products")
    
}




    
const setDisplayMenu3 =() =>{
  if(document.getElementById("menu-mobile-1").style.visibility === "hidden"){
    document.getElementById("menu-mobile-1").style.transform = "translateX(280px)"
    document.getElementById("menu-mobile-1").style.visibility = "visible"
    document.getElementById("menu-mobile-2").style.visibility = "visible"
  }
  else{
    document.getElementById("menu-mobile-1").style.transform = "translateX(280px)"
    document.getElementById("menu-mobile-1").style.visibility = "hidden"
    document.getElementById("menu-mobile-2").style.visibility = "hidden"
  }
}
        return (
            <>
             { DisplaySearch ?
                <Loader
        type="Puff"
        color="#00BFFF"
        height={100}
        width={100}
        timeout={10000} //3 secs
        className="loader-1"
      /> 
      :""
}  
<header className="header header-intro-clearance header-26">
              {/* <div className="header-top">
                <div>
                  <div style={{background  : "#95060D"}}>
                   <center>
                    <a  className="font-weight-normal">
                     <b style={{color : "white"}}>Welcome To Pak Dream Rent a Car.

                          One of the Best Rent a Car in Pakistan
                      </b> 
                      </a>
                   </center>
                    
                  </div>
                
                </div>
              </div> */}
              <div className="header-middle" style={{background:"#f1f1f1"}}>
                <div className="container">
                  <div className="header-left">
                    <button className="mobile-menu-toggler" onClick={()=>setDisplayMenu3()}>
                      <span className="sr-only">Toggle mobile menu</span>
                      <i className="icon-bars" />
                    </button>
                    <Link to="/" className="logo">
                      <img src={logo1}  alt="Pak Dream" width={105} height={25} />
                    </Link>
                  </div>
                  {/* End .header-left */}
                  {/* <div className="header-center">
                    <div className="header-search header-search-visible header-search-no-radius">
                      <a href="#" className="search-toggle" role="button">
                        <i className="icon-search" style={{transform: "rotate(280deg)"}} />
                      </a>
                      <form onSubmit={(e)=>SubmitData45342(e)}>
                        <div className="header-search-wrapper search-wrapper-wide">
                         
                          <label htmlFor="q" className="sr-only">Search</label>
                          <input type="search" className="form-control" name="q" id="q" required value={search} onChange={(e)=>setSearch(e.target.value)}  style={{ }} placeholder="Search for Cars, Hotels, brands..."/>
                          <button className="btn btn-primary" type="submit">
                            <i className="icon-search" style={{transform: "rotate(280deg)"}} />
                          </button>
                        </div>
                      </form>
                    </div>
                  </div> */}

<div class="change-fag" style={{background : "#f1f1f1"}}>

<div class="header123" style={{    margin: "auto",
    width: "fit-content"}}>
                          <div class="header123-right">
            <Link to="/about">About Us</Link>

            <Link class="dropdown1234567">
            <Link class="dropbtn">Vehicles
            <KeyboardArrowDownIcon style={{color : "black" ,marginRight: "20px",marginTop: "-4px"}}/> 
            </Link>
            <div class="dropdown1234567-content">
              <Link to="/luxury-cars">Luxury Cars</Link>
              <Link to="/standard-cars">Standard Cars</Link>
              <Link to="/suv-cars">SUV Cars</Link>
              <Link to="/budget-cars">Budget Cars</Link>
              <Link to="/coasters-cars">Coasters Cars</Link>
              <Link to="/one-way-cars">One-Way Cars</Link>
              <Link to="/monthly-cars">Monthly Rental Cars</Link>
              <Link to="/tourism">Travel & Tourism</Link>
            </div>
          </Link>
            <Link class="dropdown1234567">
            <Link class="dropbtn">Pages
            <KeyboardArrowDownIcon style={{color : "black" ,marginRight: "20px",marginTop: "-4px"}}/> 
            </Link>
            <div class="dropdown1234567-content">
              
        <Link to="/rent-a-car-in-karachi">Rent A Car in karachi</Link>
        <Link to="/rent-a-car-in-Pakistan">Rent A Car in Pakistan</Link>
        <Link to="/rent-a-car-in-Islamabad">Rent A Car in Islamabad</Link>

        <Link to="/rent-a-car-in-lahore-islamabad-karachi">Rent a Car in Lahore, Islamabad & Karachi</Link>
        <Link to="/rent-a-car-in-Karachi-Pakistan">Rent A Car in Karachi Pakistan</Link>


                                                      <Link to="/rent-a-car-in-Jhelum">Rent A Car in Jhelum</Link>
                                                      <Link to="/rent-a-car-in-Lahore">Rent A Car in Lahore</Link>
                                                      <Link to="/rent-a-car-in-Multan">Rent A Car in Multan</Link>
                                                      <Link to="/rent-a-car-in-Faisalabad">Rent A Car in Faisalabad</Link>
                                                      <Link to="/rent-a-car-in-Larkana">Rent A Car in Larkana</Link>
                                                      <Link to="/rent-a-car-in-Hyderabad">Rent A Car in Hyderabad</Link>
                                                      <Link to="/rent-a-car-in-Abbottabad">Rent A Car in Abbottabad</Link>
                                                      <Link to="/rent-a-car-in-Gujranwala">Rent A Car in Gujranwala</Link>
                                                      <Link to="/rent-a-car-in-Rahim-Yaar-Khan">Rent A Car in Rahim Yaar Khan</Link>
                                                      <Link to="/rent-a-car-in-Peshawar">Rent A Car in Peshawar</Link>
                                                      <Link to="/rent-a-car-in-Sukkur">Rent A Car in Sukkur</Link>
                                                      <Link to="/rent-a-car-in-Dadu">Rent A Car in Dadu</Link>
                                                      <Link to="/rent-a-car-in-Shahdadkot">Rent A Car in Shahdadkot</Link>



                                                      <Link to="/rent-a-car-in-Sahiwal">Rent A Car in Sahiwal</Link>
                                                      <Link to="/rent-a-car-in-Sialkot">Rent A Car in Sialkot</Link>
                                                      <Link to="/rent-a-car-in-Gujrat">Rent A Car in Gujrat</Link>
                                                      <Link to="/rent-a-car-in-Nawabshah">Rent A Car in Nawabshah</Link>
                                                    

            </div>
          </Link>

            <Link to="/Corporate-Equiries">Corporate Enquiries</Link>
            {/* <Link to="/one-way-cars">One Way Drop</Link> */}
              <Link to="/clients">Our Clients</Link>
            <Link to="/Blogs">Blogs</Link>
            <Link to="/contact">Contact Us </Link>


        </div>
        </div>
        </div>









                  {/* <div className="header-right">
                    <div className="header-dropdown-link">
                      
                      <div className="wishlist">
                        <Link to="/whislist" title="Wishlist">
                          <div className="icon">
                            <i className="icon-heart-o" />
                            <span className="wishlist-count badge">{ fav1 ? fav1.length : 0}</span>
                          </div>
                          <p>Wishlist</p>
                        </Link>
                      </div>
                      <div className="dropdown cart-dropdown">
                        <Link to="/cart" className="dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static">
                          <div className="icon">
                            <i className="icon-shopping-cart" />
                            <span className="cart-count">{ cart1 ? cart1.length :0}</span>
                          </div>
                          <p>Cart</p>
                        </Link>
                        <div className="dropdown-menu dropdown-menu-right">
                          <div className="dropdown-cart-products">


                          {
                          cart1.length >= 1 ? cart1.map((item,i)=>{
                            return(
                                <div className="product">
                                  <div className="product-cart-details">
                                    <h4 className="product-title letter-spacing-normal font-size-normal">
                                      <Link to={"/categories/"+item.Product_Catagories+"/"+item.Product_Sub_Catagories+"/"+item.Course_Sub_Catagories+"/all-products/"+item.Product_Name+"/"+item._id}>{item.Product_Name}</Link>
                                    </h4>
                                    <span className="cart-product-info">
                                      <span className="cart-product-qty">{item.Pieces}</span>
                                      x ${(item.Total_Product_Price /  item.Pieces ).toFixed(0)}
                                    </span>
                                  </div>
                                  <figure className="product-image-container">
                                    <Link to={"/categories/"+item.Product_Catagories+"/"+item.Product_Sub_Catagories+"/"+item.Course_Sub_Catagories+"/all-products/"+item.Product_Name+"/"+item._id} >
                                      <img src={item.Product_Image_Upload} alt="Product image"  onClick={()=>props.history.push("/categories/"+item.Product_Catagories+"/"+item.Product_Sub_Catagories+"/"+item.Course_Sub_Catagories+"/all-products/"+item.Product_Name+"/"+item._id)} width={200} height={300} />
                                    </Link>
                                  </figure>
                               
                              </div>

                              )
                            })
                            : <span style={{textAlign : "center"}}>Cart Empty</span>
                          }
    

                            
                          </div>
                        
                          <div className="dropdown-cart-action">
                            <Link to="/cart" className="btn btn-outline-primary-2">View Cart</Link>
                            <Link to="/checkout" className="btn btn-outline-primary-2">
                              <span>Checkout</span>
                              <i className="icon-long-arrow-right" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                   */}
                </div>
                
                {/* End .container */}
              </div>

              {/* End .header-middle */}









              <div className="header-top">
                <div className="container">
                <form onSubmit={(e)=>SubmitData45342(e)} className="mobile-search mobile-search1">
                <label htmlFor="mobile-search" className="sr-only">Search</label>
                <input type="search" name="mobile-search" id="mobile-search" required value={search} onChange={(e)=>setSearch(e.target.value)} style={{width: "100%",color : "black",background : "white" , border : " none"}} placeholder="Search for Cars, Hotels, brands..."/>
                <button className="btn btn-primary" type="submit">
                  <i className="icon-search" style={{transform: "rotate(280deg)"}} />
                </button>
              </form>
                  
                  {/* End .header-right */}
                </div>
                {/* End .container */}
              </div>






































              <div className="mobile-menu-overlay" />
          <div className="mobile-menu-container" id="menu-mobile-1">
            <div className="mobile-menu-wrapper">
              <span className="mobile-menu-close"  onClick={()=>setDisplayMenu3()}>
                <i className="icon-close" />
              </span>
              {/* <form onSubmit={(e)=>SubmitData4534(e)} className="mobile-search">
                <label htmlFor="mobile-search" className="sr-only">Search</label>
                <input type="search" className="form-control" name="mobile-search" id="mobile-search" required value={search} onChange={(e)=>setSearch(e.target.value)} style={{ }} placeholder="Search for Cars, Hotels, brands..."/>
                <button className="btn btn-primary" type="submit">
                  <i className="icon-search" style={{transform: "rotate(280deg)"}} />
                </button>
              </form> */}
              <ul className="nav nav-pills-mobile" role="tablist">
                <li className="nav-item">
                  <a className="nav-link font-size-normal second-primary-color font-weight-normal text-uppercase active" id="mobile-cats-link" data-toggle="tab" href="#mobile-cats-tab" role="tab" aria-controls="mobile-cats-tab" aria-selected="false">Menu</a>
                </li>
                {/* <li className="nav-item">
                  <a className="nav-link font-size-normal second-primary-color font-weight-normal text-uppercase" id="mobile-menu-link" data-toggle="tab" href="#mobile-menu-tab" role="tab" aria-controls="mobile-menu-tab" aria-selected="true">Menu</a>
                </li> */}
              </ul>
              <div className="tab-content">
                <div className="tab-pane fade" id="mobile-menu-tab" role="tabpanel" aria-labelledby="mobile-menu-link">
                  <nav className="mobile-nav">
                    <ul className="mobile-menu">
                      <li className="active">
                        <Link onClick={()=>setDisplayMenu3()} to="/">Home</Link>
                      </li>
                      <li>
                        <Link onClick={()=>setDisplayMenu3()}  to="/about">About</Link>
                      </li>
                      <li className="active">
                        <Link onClick={()=>setDisplayMenu3()} to="/luxury-cars">Luxury Cars</Link>
                      </li>
                      <li className="active">
                        <Link onClick={()=>setDisplayMenu3()} to="/standard-cars">Standard Cars</Link>
                      </li>
                      <li className="active">
                        <Link onClick={()=>setDisplayMenu3()} to="/suv-cars">SUV Cars</Link>
                      </li>
                      <li className="active">
                        <Link onClick={()=>setDisplayMenu3()} to="/budget-cars">Budget Cars</Link>
                      </li>
                      <li className="active">
                        <Link onClick={()=>setDisplayMenu3()} to="/coasters-cars">Coasters Cars</Link>
                      </li>
                      <li className="active">
                        <Link onClick={()=>setDisplayMenu3()} to="/one-way-cars">One-Way Cars</Link>
                      </li>
                      <li>
                        <Link onClick={()=>setDisplayMenu3()}  to="/Corporate-Equiries">Corporate Equiries</Link>
                      </li>
                      <li>
                        <Link onClick={()=>setDisplayMenu3()}  to="/Blogs">Blogs</Link>
                      </li>
                      <li>
                        <Link onClick={()=>setDisplayMenu3()}  to="/contact">Contact</Link>
                      </li>
                      <li>
                        <Link onClick={()=>setDisplayMenu3()}  to="/cart">Cart</Link>
                      </li>
                      <li>
                        <Link onClick={()=>setDisplayMenu3()}  to="/checkout">Checkout</Link>
                      </li>
                      
                      
                      
                      
                      
                      
                      <li>
                        {/* <a >Shop <span class="mmenu-btn"></span></a> */}
                        <ul>
                          
                          {/* <li>
                            <Link onClick={()=>setDisplayMenu3()}  to="/shop-all">Shop All</Link>
                          </li>
                          <li>
                            <Link onClick={()=>setDisplayMenu3()}  to="/product/new-arrival">Today's Best</Link>
                          </li> */}
                          {/* <li>
                            <Link onClick={()=>setDisplayMenu3()}  to="/product/expert-pick">
                              <span>Trending
                                <span className="tip tip-hot">Hot</span>
                              </span>
                            </Link>
                          </li> */}
                          {/* <li>
                            <Link onClick={()=>setDisplayMenu3()}  to="/product/recommended-product">Features Product</Link>
                          </li> */}
                         {/*  <li>
                            <Link onClick={()=>setDisplayMenu3()}  to="/product/recommended-product">
                              <span>Recommended Product
                                <span className="tip tip-new">New</span>
                              </span>
                            </Link>
                          </li> */}
                          <li>
                            <Link onClick={()=>setDisplayMenu3()}  to="/cart">Cart</Link>
                          </li>
                          <li>
                            <Link onClick={()=>setDisplayMenu3()}  to="/checkout">Checkout</Link>
                          </li>
                          {/* <li>
                            <Link onClick={()=>setDisplayMenu3()}  to="/whislist">Wishlist</Link>
                          </li> */}
                          {/* <li>
                            <a href="#">Lookbook</a>
                          </li> */}
                        </ul>
                      </li>
                      {/* <li>
                        <a href="product.html" className="sf-with-ul">Product</a>
                        <ul>
                          <li>
                            <a href="product.html">Default</a>
                          </li>
                          <li>
                            <a href="product-centered.html">Centered</a>
                          </li>
                          <li>
                            <a href="product-extended.html">
                              <span>Extended Info
                                <span className="tip tip-new">New</span>
                              </span>
                            </a>
                          </li>
                          <li>
                            <a href="product-gallery.html">Gallery</a>
                          </li>
                          <li>
                            <a href="product-sticky.html">Sticky Info</a>
                          </li>
                          <li>
                            <a href="product-sidebar.html">Boxed With Sidebar</a>
                          </li>
                          <li>
                            <a href="product-fullwidth.html">Full Width</a>
                          </li>
                          <li>
                            <a href="product-masonry.html">Masonry Sticky Info</a>
                          </li>
                        </ul>
                      </li> */}
                      <li>
                        <Link>Pages <span class="mmenu-btn"></span></Link>
                        <ul>
                          <li>
                            <Link onClick={()=>setDisplayMenu3()}  to="/about">About</Link>
                            {/* <ul>
                              <li>
                                <Link to="/Linkbout">About 01</Link>
                              </li>
                              <li>
                                <Link to="/Linkbout-2">About 02</Link>
                              </li>
                            </ul> */}
                          </li>
                          <li>
                            <Link onClick={()=>setDisplayMenu3()}  to="/contact">Contact</Link>
                            {/* <ul>
                              <li>
                                <Link to="/contact">Contact 01</Link>
                              </li>
                              <li>
                                <Link to="/contact-2">Contact 02</Link>
                              </li>
                            </ul> */}
                          </li>
                          {/* <li>
                            <Link onClick={()=>setDisplayMenu3()}  to="/login">Login</Link>
                          </li> */}
                          <li>
                            <Link onClick={()=>setDisplayMenu3()}  to="/help">FAQs</Link>
                          </li>
                          {/* <li>
                            <Link onClick={()=>setDisplayMenu3()}  to="*">Error 404</Link>
                          </li> */}
                         
                        </ul>
                      </li>
                    
                    </ul>
                  </nav>
                  {/* End .mobile-nav */}
                </div>
                {/* .End .tab-pane */}
                <div className="tab-pane fade show active" id="mobile-cats-tab" role="tabpanel" aria-labelledby="mobile-cats-link">
                  <nav className="mobile-cats-nav">
                    <ul className="mobile-cats-menu">
                    <li className="active">
                        <Link onClick={()=>setDisplayMenu3()} to="/">Home</Link>
                      </li>
                      <li>
                        <Link onClick={()=>setDisplayMenu3()}  to="/about">About</Link>
                      </li>
                      <li className="active">
                        <Link onClick={()=>setDisplayMenu3()} to="/luxury-cars">Luxury Cars</Link>
                      </li>
                      <li className="active">
                        <Link onClick={()=>setDisplayMenu3()} to="/standard-cars">Standard Cars</Link>
                      </li>
                      <li className="active">
                        <Link onClick={()=>setDisplayMenu3()} to="/suv-cars">SUV Cars</Link>
                      </li>
                      <li className="active">
                        <Link onClick={()=>setDisplayMenu3()} to="/budget-cars">Budget Cars</Link>
                      </li>
                      <li className="active">
                        <Link onClick={()=>setDisplayMenu3()} to="/coasters-cars">Coasters Cars</Link>
                      </li>
                      <li className="active">
                        <Link onClick={()=>setDisplayMenu3()} to="/one-way-cars">One-Way Cars</Link>
                      </li>
                      <li>
                        <Link onClick={()=>setDisplayMenu3()}  to="/Corporate-Equiries">Corporate Equiries</Link>
                      </li>
                      <li>
                        <Link onClick={()=>setDisplayMenu3()}  to="/contact">Contact</Link>
                      </li>
                      <li>
                        <Link onClick={()=>setDisplayMenu3()}  to="/cart">Cart</Link>
                      </li>
                      <li>
                        <Link onClick={()=>setDisplayMenu3()}  to="/checkout">Checkout</Link>
                      </li>
                      <li>
                        <Link  onClick={()=>setDisplayMenu3()} to="/tourism">Tourism</Link>
                      </li>
                      <li>
                        <Link  onClick={()=>setDisplayMenu3()} to="/Corporate-Equiries">Corporate Equiries</Link>
                      </li>
                      <li>
                        <Link  onClick={()=>setDisplayMenu3()} to="/Contact"> Contact Us</Link>
                      </li>
                      
                    {/* {MainCatogories.map((res,i)=>{
                         if(res.MainCategories === "One Way Drop"){
                          return (
                         <>
                
                        <li>
                        <a className="mobile-cats-lead" href={"/main-categories-car-rental/"+res.MainCategories }>{res.MainCategories}</a>
                      </li>
                       </>
                    )
                  }
                  else{
                    return (
                   <>
<li>
                        <a className="mobile-cats-lead" href={"/main-categories/"+res.MainCategories }>{res.MainCategories}</a>
                      </li>
                       </>


                     )
                   }
                            
                            
                   
                  
                  })
                

                              } */}

 {/* <li>
                        <Link className="mobile-cats-lead" to="/tourism">Tourism</Link>
                      </li>
                      <li>
                        <Link className="mobile-cats-lead" href={"/Corporate-Equiries"}>Corporate Equiries</Link>
                      </li>
                      <li>
                        <Link className="mobile-cats-lead" href={"/Contact"}> Contact Us</Link>
                      </li> */}
                      {/* <li>
                        <Link className="mobile-cats-lead" href={"/About"}>About Us</Link>
                      </li> */}
                      {/* <li>
                        <a className="mobile-cats-lead" href="#">Daily offers</a>
                      </li>
                      <li>
                        <a className="mobile-cats-lead" href="#">Gift Ideas</a>
                      </li> */}
                     
                     
                     
                     
                     
                      {/* <li>
                        <a href="#">Beds</a>
                      </li>
                      <li>
                        <a href="#">Lighting</a>
                      </li>
                      <li>
                        <a href="#">Sofas &amp;Sleeper sofas</a>
                      </li>
                      <li>
                        <a href="#">Storage</a>
                      </li>
                      <li>
                        <a href="#">Armchairs &amp;Chaises</a>
                      </li>
                      <li>
                        <a href="#">Decoration </a>
                      </li>
                      <li>
                        <a href="#">Kitchen Cabinets</a>
                      </li>
                      <li>
                        <a href="#">Coffee &amp;Tables</a>
                      </li>
                      <li>
                        <a href="#">Outdoor Furniture </a>
                      </li> */}
                    </ul>
                    {/* End .mobile-cats-menu */}
                  </nav>
                  {/* End .mobile-cats-nav */}
                </div>
                {/* .End .tab-pane */}
              </div>
              {/* End .tab-content */}
              <div className="social-icons justify-content-center">
                <a href="https://www.facebook.com/Rentacarpd" className="social-icon" target="_blank" title="Facebook">
                  <i className="icon-facebook-f" />
                </a>
                {/* <a href="#" className="social-icon" target="_blank" title="Twitter">
                  <i className="icon-twitter" />
                </a> */}
                <a href="https://www.instagram.com/rentacarspd/" className="social-icon" target="_blank" title="Instagram">
                  <i className="icon-instagram" />
                </a>
                {/* <a href="#" className="social-icon" target="_blank" title="Youtube">
                  <i className="icon-youtube" />
                </a> */}
              </div>
              {/* End .social-icons */}
            </div>
            {/* End .mobile-menu-wrapper */}
          </div>















          <div className="mobile-menu-container1" id="menu-mobile-2" onClick={()=>setDisplayMenu3()}>
            <div className="mobile-menu-wrapper1">
              {/* End .social-icons */}
            </div>
            {/* End .mobile-menu-wrapper */}
          </div>













          {/* End .mobile-menu-container */}
          {/* Sign in / Register Modal */}
          <div className="modal fade" id="signin-modal" tabIndex={-1} role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">
                      <i className="icon-close" />
                    </span>
                  </button>
                  
                  {/* End .form-box */}
                </div>
                {/* End .modal-body */}
              </div>
              {/* End .modal-content */}
            </div>
            {/* End .modal-dialog */}
          </div>
          {/* End .modal */}
              {/* End .header-bottom */}
            </header>
            
{/* <div class="change-fag" style={{background : "#f1f1f1"}}>


<div class="header123" style={{    margin: "auto",
    width: "fit-content"}}>
  <div class="header123-right">
    <Link to="/about">About Us</Link>

    <a class="dropdown123456">
    <Link class="dropbtn">Vehicles
    <KeyboardArrowDownIcon style={{color : "black" ,marginRight: "20px",marginTop: "-4px"}}/> 
    </Link>
    <div class="dropdown123456-content">
      <Link to="/luxury-cars">Luxury Cars</Link>
      <Link to="/standard-cars">Standard Cars</Link>
      <Link to="/suv-cars">SUV Cars</Link>
      <Link to="/budget-cars">Budget Cars</Link>
      <Link to="/coasters-cars">Coasters Cars</Link>
      <Link to="/one-way-cars">One-Way Cars</Link>
      <Link to="/monthly-cars">Monthly Rental Cars</Link>
      <Link to="/tourism">Travel & Tourism</Link>
    </div>
  </a>
    <a class="dropdown123456">
    <Link class="dropbtn">Pages
    <KeyboardArrowDownIcon style={{color : "black" ,marginRight: "20px",marginTop: "-4px"}}/> 
    </Link>
    <div class="dropdown123456-content">
      
<Link to="/rent-a-car-in-karachi">Rent A Car in karachi</Link>
<Link to="/rent-a-car-in-Pakistan">Rent A Car in Pakistan</Link>
<Link to="/rent-a-car-in-Islamabad">Rent A Car in Islamabad</Link>

<Link to="/rent-a-car-in-lahore-islamabad-karachi">Rent a Car in Lahore, Islamabad & Karachi</Link>
<Link to="/rent-a-car-in-Karachi-Pakistan">Rent A Car in Karachi Pakistan</Link>


                                              <Link to="/rent-a-car-in-Jhelum">Rent A Car in Jhelum</Link>
                                              <Link to="/rent-a-car-in-Lahore">Rent A Car in Lahore</Link>
                                              <Link to="/rent-a-car-in-Multan">Rent A Car in Multan</Link>
                                              <Link to="/rent-a-car-in-Faisalabad">Rent A Car in Faisalabad</Link>
                                              <Link to="/rent-a-car-in-Larkana">Rent A Car in Larkana</Link>
                                              <Link to="/rent-a-car-in-Hyderabad">Rent A Car in Hyderabad</Link>
                                              <Link to="/rent-a-car-in-Abbottabad">Rent A Car in Abbottabad</Link>
                                              <Link to="/rent-a-car-in-Gujranwala">Rent A Car in Gujranwala</Link>
                                              <Link to="/rent-a-car-in-Rahim-Yaar-Khan">Rent A Car in Rahim Yaar Khan</Link>
                                              <Link to="/rent-a-car-in-Peshawar">Rent A Car in Peshawar</Link>
                                              <Link to="/rent-a-car-in-Sukkur">Rent A Car in Sukkur</Link>
                                              <Link to="/rent-a-car-in-Dadu">Rent A Car in Dadu</Link>
                                              <Link to="/rent-a-car-in-Shahdadkot">Rent A Car in Shahdadkot</Link>



                                              <Link to="/rent-a-car-in-Sahiwal">Rent A Car in Sahiwal</Link>
                                              <Link to="/rent-a-car-in-Sialkot">Rent A Car in Sialkot</Link>
                                              <Link to="/rent-a-car-in-Gujrat">Rent A Car in Gujrat</Link>
                                              <Link to="/rent-a-car-in-Nawabshah">Rent A Car in Nawabshah</Link>
                                            

    </div>
  </a>

    <Link to="/Corporate-Equiries">Corporate Enquiries</Link>
	    <Link to="/clients">Our Clients</Link>
    <Link to="/Blogs">Blogs</Link>
    <Link to="/contact">Contact Us </Link>


</div>
</div>
</div> */}

        </>
        )
}

export default Header;